"use client";
import React, { useState, useCallback } from "react";
import styles from "./InputDesign.module.css";
import PagesSidebar from "./PagesSidebar";
import PageContent from "./PageContent";
import PreviewArea from "./PreviewArea";
import StyleEditor from "./StyleEditor";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const MenuEditor = () => {
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [draggedPage, setDraggedPage] = useState(null);
  const [draggedOverPage, setDraggedOverPage] = useState(null);
  const [showStyle, setShowStyle] = React.useState(false);
  const [showJsonDialog, setShowJsonDialog] = React.useState(false);
  const [styleSettings, setStyleSettings] = React.useState({
    backgroundColor: "#fdf7eb",
    closeColor: "#00FF00",
    detailBackgroundColor: "#ec704a",
    detailCloseColor: "#0220FF",
    detailDescColor: "#FFFFFF",
    detailPriceColor: "#FFFFFF",
    detailTextColor: "#FFFFFF",
    priceColor: "#A4D0CA",
    textColor: "#132941",
    textFont: {
      name: "Montserrat",
      location: "https://localhost:3000/fonts/Montserrat-Regular.ttf",
    },
    titleFont: {
      location: "https://localhost:3000/fonts/Bestro.ttf",
      name: "Bestro",
    },
  });

  const addPage = useCallback(() => {
    const newPages = [
      ...pages,
      { pageType: { fr: "", en: "" }, pageEntries: [] },
    ];
    setPages(newPages);
    setSelectedPage(newPages.length - 1);
    setShowStyle(false);
  }, [pages]);

  const deletePage = useCallback(
    (index) => {
      const newPages = [...pages];
      newPages.splice(index, 1);
      setPages(newPages);
      setSelectedPage(null);
    },
    [pages]
  );

  const addPageEntry = useCallback(() => {
    if (selectedPage !== null) {
      const newPages = [...pages];
      newPages[selectedPage].pageEntries.push({
        entryType: "dishList",
        entries: [],
      });
      setPages(newPages);
    }
  }, [selectedPage, pages]);

  const deletePageEntry = useCallback(
    (index) => {
      if (selectedPage !== null) {
        const newPages = [...pages];
        newPages[selectedPage].pageEntries.splice(index, 1);
        setPages(newPages);
      }
    },
    [selectedPage, pages]
  );

  const addEntry = useCallback(
    (pageEntryIndex) => {
      if (selectedPage !== null) {
        const newPages = [...pages];
        newPages[selectedPage].pageEntries[pageEntryIndex].entries.push({
          entryId: Date.now(),
          entryTitle: { fr: "", en: "" },
          entrySubtitle: { fr: "", en: "" },
          entryPrice: { fr: [""], en: [""] },
        });
        setPages(newPages);
      }
    },
    [selectedPage, pages]
  );

  const deleteEntry = useCallback(
    (pageEntryIndex, entryIndex) => {
      if (selectedPage !== null) {
        const newPages = [...pages];
        newPages[selectedPage].pageEntries[pageEntryIndex].entries.splice(
          entryIndex,
          1
        );
        setPages(newPages);
      }
    },
    [selectedPage, pages]
  );

  const translatePage = useCallback(() => {
    if (selectedPage !== null) {
      const newPages = [...pages];
      const page = newPages[selectedPage];

      // Translate page type
      if (page.pageType.fr && !page.pageType.en) {
        page.pageType.en = page.pageType.fr;
      }

      // Translate page entries
      page.pageEntries.forEach((pageEntry) => {
        // Translate text
        if (pageEntry.text && pageEntry.text.fr && !pageEntry.text.en) {
          pageEntry.text.en = pageEntry.text.fr;
        }

        // Translate entries title
        if (
          pageEntry.entriesTitle &&
          pageEntry.entriesTitle.fr &&
          !pageEntry.entriesTitle.en
        ) {
          pageEntry.entriesTitle.en = pageEntry.entriesTitle.fr;
        }

        // Translate entries
        if (pageEntry.entries) {
          pageEntry.entries.forEach((entry) => {
            // Translate entry title
            if (
              entry.entryTitle &&
              entry.entryTitle.fr &&
              !entry.entryTitle.en
            ) {
              entry.entryTitle.en = entry.entryTitle.fr;
            }

            // Translate entry subtitle
            if (
              entry.entrySubtitle &&
              entry.entrySubtitle.fr &&
              !entry.entrySubtitle.en
            ) {
              entry.entrySubtitle.en = entry.entrySubtitle.fr;
            }

            // Translate entry price
            if (
              entry.entryPrice &&
              entry.entryPrice.fr &&
              (!entry.entryPrice.en || entry.entryPrice.en.length === 0)
            ) {
              entry.entryPrice.en = [...entry.entryPrice.fr];
            }
          });
        }
      });

      setPages(newPages);
    }
  }, [selectedPage, pages]);

  const saveChanges = useCallback(async () => {
    setIsSaving(true);
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowJsonDialog(true);
    } catch (error) {
      alert("Erreur lors de la sauvegarde");
    } finally {
      setIsSaving(false);
    }
  }, []);

  const handleCloseDialog = () => {
    setShowJsonDialog(false);
  };

  const handleDragStart = useCallback((index) => {
    setDraggedPage(index);
  }, []);

  const handleDragOver = useCallback((event, index) => {
    event.preventDefault();
    setDraggedOverPage(index);
  }, []);

  const handleDrop = useCallback(
    (event, index) => {
      event.preventDefault();

      if (draggedPage === null || draggedPage === index) return;

      const newPages = [...pages];
      const pageToMove = newPages[draggedPage];

      // Remove the page from its original position
      newPages.splice(draggedPage, 1);

      // Insert the page at the new position
      newPages.splice(index, 0, pageToMove);

      setPages(newPages);

      // Update selected page if needed
      if (selectedPage === draggedPage) {
        setSelectedPage(index);
      } else if (selectedPage === index) {
        // If dropping on the selected page, adjust the selection
        if (draggedPage < index) {
          setSelectedPage(selectedPage - 1);
        } else {
          setSelectedPage(selectedPage + 1);
        }
      }

      setDraggedPage(null);
      setDraggedOverPage(null);
    },
    [draggedPage, pages, selectedPage]
  );

  const handleDragEnd = useCallback(() => {
    setDraggedPage(null);
    setDraggedOverPage(null);
  }, []);

  const toggleStyleEditor = () => {
    if (!showStyle) {
      setSelectedPage(null);
    }
    setShowStyle(!showStyle);
  };

  const handlePageSelect = useCallback((index) => {
    setSelectedPage(index);
    setShowStyle(false);
  }, []);

  return (
    <main className={styles.flexFlexColFont}>
      <div className={styles.mainContent}>
        <PagesSidebar
          pages={pages}
          selectedPage={selectedPage}
          setSelectedPage={handlePageSelect}
          addPage={addPage}
          deletePage={deletePage}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          handleDragEnd={handleDragEnd}
          draggedOverPage={draggedOverPage}
          showStyle={showStyle}
          toggleStyleEditor={toggleStyleEditor}
        />

        {selectedPage !== null && (
          <PageContent
            selectedPage={selectedPage}
            pages={pages}
            updatePages={setPages}
            addPageEntry={addPageEntry}
            deletePageEntry={deletePageEntry}
            addEntry={addEntry}
            deleteEntry={deleteEntry}
            translatePage={translatePage}
            saveChanges={saveChanges}
            isSaving={isSaving}
            setSelectedPage={setSelectedPage}
            setPages={setPages}
          />
        )}

        {showStyle && (
          <StyleEditor
            styleSettings={styleSettings}
            setStyleSettings={setStyleSettings}
            toggleStyleEditor={toggleStyleEditor}
          />
        )}

        <PreviewArea />

        <Dialog open={showJsonDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>Contenu de la page</DialogTitle>
          <DialogContent>
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {JSON.stringify(selectedPage !== null ? pages[selectedPage] : {}, null, 2)}
            </pre>
          </DialogContent>
          <DialogActions>
            <button onClick={handleCloseDialog} className={styles.primaryButton}>
              Fermer
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
};

export default MenuEditor;
