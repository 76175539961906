import Iframe from "react-iframe";

function WebPageMock(props){

    if (props.open){

        if (props.imageOverWebsite)
            return (
                <div style={{position: "absolute", width: props.width, height: props.height}} onClick={props.close}>
                    <img alt="app preview" src={props.url}
                        width={props.width}
                        height={props.height}
                        style={{position: 'absolute', zIndex: 20, ...props.imgStyle}}/>
                </div>);
        else
            return (
                <div style={{position: "absolute", width: props.width, height: props.height}} onClick={props.close}>
                    <Iframe id={props.id} url={props.url}
                        width={props.width}
                        height={props.height}
                        styles={{position: 'absolute', zIndex: 20, ...props.iframeStyle}}/>
                </div>);
    }
    else return null;
}

export default WebPageMock;