import { useContext } from "react";
import { PlayerContext } from "../../PlayerContext";
import { checkMobile } from "../../Utils";
import { Image } from "react-native-web";
import CloseButton from "../../side_menu/components/CloseButton";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

function Promotion(props){
    const playMode = useContext(PlayerContext).get("playMode");
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const sizeMultiplier = useContext(PlayerContext).get("sizeMultiplier"); 
    
    return (
        <div style={{pointerEvents: 'auto'}}>                       
            <div id="promotion-container" className={'promotion-container'} style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight,}}>                                                                
                {playMode === "preview" && !checkMobile() && <div style={{width: 1, height: storyDimensions.containerHeight, backgroundColor: '#b2ede7', position: 'absolute', left: 0, bottom: 0}} />}                 
                <div className="FullScreenContainer" style={{backgroundColor: props.story.promotionBackground}}>              
                    <TransformWrapper>
                        <TransformComponent>
                            <img alt="full screen promotion" src={props.story.promotionImgUrl} style={{objectFit: "contain", width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}}/>                         
                        </TransformComponent>
                    </TransformWrapper>                              
                </div>
                <div style={{position: 'absolute', zIndex: 30, pointerEvents: "auto", top: storyDimensions.containerHeight - 48 * sizeMultiplier - 8 * sizeMultiplier, left: storyDimensions.containerWidth -2 - 48 * sizeMultiplier - 8 * sizeMultiplier, }}>
                    <CloseButton onClick={props.close}  color={props.story.primaryColor}/>
                </div>  
            </div>
        </div>
    );
}  

export default Promotion;