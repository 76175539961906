import './../../Player.css';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import { browserLocales, isAndroid, isInstagramInAppBrowser, isTikTokInAppBrowser, openFullscreen, printObj, weekdayTextToGroupedByDaysOpeningHours } from '../../Utils';
import { analyticsManager } from '../../../index';
import SecondaryButton, {hoursType} from '../components/SecondaryButton';
import { appAnalyticsManager } from '../../Player';
import * as Sentry from "@sentry/react";
import LineBreak from '../../../maker/component/LineBreak';
import { set } from 'firebase/database';
import CloseButton from '../components/CloseButton';
import { useAnimate } from 'framer-motion';
import { Hidden } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Image } from 'react-native-web';

function HoursButton(props){
    const [isOpened, setIsOpened] = useState(false);

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");
    const sizeMultiplier = useContext(PlayerContext).get("sizeMultiplier");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");
    const idPrefix = fromDemo ? (fromDemo +"-") : "";
    const [showTexts, setShowTexts] = useState(false);

    const { t } = useTranslation();    
    const closeWidth = 55 * sizeMultiplier;
    const closeHeight = 53 * sizeMultiplier;
    const brushesWidth = sizeMultiplier === 1 ? 360 : 360 * sizeMultiplier * 0.90;
    let brushesHeight = sizeMultiplier === 1 ? 429 : 429 * sizeMultiplier * 0.90;
    const hoursLong = props.placeDetails && props.story.secondary_opening_hours;
    brushesHeight = hoursLong ? (sizeMultiplier === 1 ? Math.min(590,storyDimensions.containerHeight) : Math.min(756,storyDimensions.containerHeight) * sizeMultiplier * 0.90) : brushesHeight;
    const secondaryOpeningHours = props.story.secondary_opening_hours ? new Map() : null;

    if (secondaryOpeningHours){
        const frJsonString = props.story.secondary_opening_hours.fr.replaceAll("\\\"", "\"");
        const frParsedJson = JSON.parse(frJsonString);
        secondaryOpeningHours["fr"] = frParsedJson.secondary_opening_hours;
        const enJsonString = props.story.secondary_opening_hours.en.replaceAll("\\\"", "\"");
        const enParsedJson = JSON.parse(enJsonString);
        secondaryOpeningHours["en"] = enParsedJson.secondary_opening_hours;
    }

    const closeBtnX = hoursLong && ((storyDimensions.containerHeight - brushesHeight)/2 < closeHeight) ? storyDimensions.containerWidth - closeWidth - 8 * sizeMultiplier : ((storyDimensions.containerWidth - brushesWidth) / 2 + brushesWidth - closeWidth - 28 * sizeMultiplier);
    const closeBtnY = hoursLong && ((storyDimensions.containerHeight - brushesHeight)/2 < closeHeight) ? storyDimensions.containerHeight - closeHeight - 8 * sizeMultiplier : ((storyDimensions.containerHeight - brushesHeight) / 2 + brushesHeight);
    const titleY = (storyDimensions.containerHeight - brushesHeight) / 2 + 32 * sizeMultiplier + (hoursLong ? 16 : 3) * sizeMultiplier;
    const separatorHeight = 2 * sizeMultiplier;
    const separatorWidth = 156 * sizeMultiplier;
    const separatorY = (storyDimensions.containerHeight - brushesHeight) / 2 + 84 * sizeMultiplier + (hoursLong ? 22 : 1) * sizeMultiplier;
    let openingsY = (storyDimensions.containerHeight - brushesHeight) / 2 + (96 + 6) * sizeMultiplier;
    const googleY = (storyDimensions.containerHeight - brushesHeight) / 2 + brushesHeight - 20 * sizeMultiplier;

    const [scope, animate] = useAnimate();

    useEffect(() => {
        if (props.story && props.show && isOpened && activeFeature === "hours"){           
            const galleryOverlay = document.getElementById("gallery-overlay");
            if (galleryOverlay != null){  
                const animation = async () => {                       
                    animate(galleryOverlay, { opacity: 1}, {duration: 0.3});                    
                }
                
                animation();
            }                        
        }
    }, [props.story, props.show, isOpened, activeFeature]);

    if (props.story.posId == null) return (null);

    const open = () => {                
        if (!fromMaker /*&& !fromDemo*/ && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){                        
            const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
               try {
                  ambiantPlayer.play();                
               } catch(error){}
            }
            setPlayAudio(true);            
        }
        pauseVideo();
        openFullscreen();
        setActiveFeature("hours");
        setIsOpened(true);
      
        analyticsManager.logCheckHoursTag(props.storyId, playMode, true);
                
        if (appAnalyticsManager)
            appAnalyticsManager.clickedHours();

        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "hours",
                    }
                ],
            });            
        }

        if (window.fbq){            
            window.fbq('track', 'ViewContent', {"content_name": "hours"});
        }
      };

      const close = () => {        
        propagateFocusChange(true);
        setIsOpened(false);
        setActiveFeature(null);
      };    
      
      function cutMultipleHours(hourText){
         const splittedLine = hourText.split(",");         
         return splittedLine.map((line)=>{            
             let carriageReturn = false;
             if (splittedLine.indexOf(line) > 0){
                 carriageReturn = true;
             }
             return <>{carriageReturn && <br/>}{line.trim()}</>;
         });
     }
    
      let showExtendedHoursInfo = false;
      if (props.show){      
        // console.log(JSON.stringify(props.placeDetails.currentOpeningHours));
        const language = browserLocales(true)[0] === "fr" ? "fr" : "en";
        if (props.storyId === "LnSX2qKrthKsRmr1vcxc" && props.placeDetails){
            if (language === "fr"){
                props.placeDetails.currentOpeningHours = hoursLyon;
            }
            else {
               props.placeDetails.currentOpeningHours = hoursLyonEn;
            }
            showExtendedHoursInfo = true;
        }
        if (props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" && props.placeDetails){
         if (language === "fr"){
            props.placeDetails.currentOpeningHours = hoursStrasbourg;
         }
         else {
            props.placeDetails.currentOpeningHours = hoursStrasbourgEn;
         }
         showExtendedHoursInfo = true;
         }
         if (props.storyId === "zUEpyuVwo5Tws3hnvFds" && props.placeDetails){
            if (language === "fr"){
               props.placeDetails.currentOpeningHours = hoursBordeaux;
            }
            else {
               props.placeDetails.currentOpeningHours = hoursBordeauxEn;
            }
            showExtendedHoursInfo = true;
        }
        if (props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" && props.placeDetails){
         if (language === "fr"){
            props.placeDetails.currentOpeningHours = hoursParis;
         }
         else {
            props.placeDetails.currentOpeningHours = hoursParisEn;
         }
         showExtendedHoursInfo = true;
         }

        if (isOpened && activeFeature === "hours"){
            let hoursFontSize = 22;
            let weekDayOpenings = null;
            let secondaryWeekDayOpenings = "";

            if (!hoursLong){
                const weekDayOpeningsTable = weekdayTextToGroupedByDaysOpeningHours(props.placeDetails.currentOpeningHours.weekday_text);             
                if (weekDayOpeningsTable.format === "day"){
                    hoursFontSize = 18;                    
                    weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                    <>{weekDayOpening === weekDayOpeningsTable.openingHours[0] ? <><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/></> : null}
                    <tr/><tr/>
                    <tr key={weekDayOpening}><td style={{textAlign: 'center'}}>{
                        cutMultipleHours(weekDayOpening)
                        }</td></tr></>
                    );
                }
                else if (weekDayOpeningsTable.format === "grouped"){
                    hoursFontSize = 22;
                    weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                        {                            
                            let lineBreak = null;
                            if (weekDayOpening === weekDayOpeningsTable.openingHours[0] && weekDayOpeningsTable.format === "grouped"){
                                if (weekDayOpeningsTable.openingHours.length === 1){
                                    lineBreak = <><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/></>;
                                }
                                else if (weekDayOpeningsTable.openingHours.length === 2){
                                    lineBreak = <><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/></>;
                                }
                                else if (weekDayOpeningsTable.openingHours.length === 3){
                                    lineBreak = <><tr/><tr/><tr/><tr/><tr/></>;
                                }
                            }
                            
                            if (weekDayOpening.startingDay === weekDayOpening.endingDay){
                                return (<>{lineBreak}<tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                               
                                    weekDayOpening.startingDay} 
                                </td></tr>
                                <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>                               
                                    <i>{cutMultipleHours(weekDayOpening.hours)}</i>                        
                                    </td></tr><tr/><tr/>
                                <tr/><tr/></>);
                            }
                            else {
                                return (
                                <>{lineBreak}
                                <tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                                
                                    t('HoursFromTo', {from: weekDayOpening.startingDay, to: weekDayOpening.endingDay})                         
                                    }</td></tr>
                                <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>{                                
                                    cutMultipleHours(weekDayOpening.hours)                      
                                    }</td></tr><tr/><tr/>
                                </>);
                            }                                                    
                        });
                }    
            }       
            else {
                const weekDayOpeningsTable = weekdayTextToGroupedByDaysOpeningHours(props.placeDetails.currentOpeningHours.weekday_text);
                openingsY += 40 * sizeMultiplier;
                if (weekDayOpeningsTable.format === "day"){
                    hoursFontSize = 18;                    
                    weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                    <>
                        <tr/><tr/>
                        <tr key={weekDayOpening}><td style={{textAlign: 'center'}}>{
                            cutMultipleHours(weekDayOpening)
                            }</td></tr>
                    </>
                    );
                }
                else if (weekDayOpeningsTable.format === "grouped"){
                    hoursFontSize = 22;
                    weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                        {                                             
                            if (weekDayOpening.startingDay === weekDayOpening.endingDay){
                                return (<><tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                               
                                    weekDayOpening.startingDay} 
                                </td></tr>
                                <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>                               
                                    <i>{cutMultipleHours(weekDayOpening.hours)}</i>                        
                                    </td></tr><tr/><tr/>
                                <tr/><tr/></>);
                            }
                            else {
                                return (
                                <>
                                <tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                                
                                    t('HoursFromTo', {from: weekDayOpening.startingDay, to: weekDayOpening.endingDay})                         
                                    }</td></tr>
                                <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>{                                
                                    cutMultipleHours(weekDayOpening.hours)                        
                                    }</td></tr><tr/><tr/>
                                </>);
                            }                                                    
                        });
                    
                }
                if (secondaryOpeningHours){                        
                    const language = browserLocales(true)[0] === "fr" ? "fr" : "en";                            
                    secondaryWeekDayOpenings = secondaryOpeningHours[language].map((openingHours)=>{
                        const weekDayOpeningsTable = weekdayTextToGroupedByDaysOpeningHours(openingHours.weekday_text);                            
                        const weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                            {           
                                const title = weekDayOpening === weekDayOpeningsTable.openingHours[0] ?<><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center', fontWeight: "bold"}}>{t(openingHours.type)}</td></tr></> : "";                                                                                 
                                if (weekDayOpening.startingDay === weekDayOpening.endingDay){
                                    return (<>{title}<tr key={weekDayOpening.startingDay}><td style={{textAlign: 'left'}}>{                               
                                        weekDayOpening.startingDay} 
                                    </td></tr>
                                    <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'left', fontStyle: 'italic'}}>                               
                                        <i>{cutMultipleHours(weekDayOpening.hours)}</i>                        
                                        </td></tr><tr/><tr/>
                                    <tr/><tr/></>);
                                }
                                else {
                                    return (
                                    <>{title}
                                    <tr key={weekDayOpening.startingDay}><td style={{textAlign: 'left'}}>{                                
                                        t('HoursFromTo', {from: weekDayOpening.startingDay, to: weekDayOpening.endingDay})                         
                                        }</td></tr>
                                    <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'left', fontStyle: 'italic'}}>{                                
                                        cutMultipleHours(weekDayOpening.hours)                        
                                        }</td></tr><tr/><tr/>
                                    </>);
                                }                                                    
                            });    
                        return weekDayOpenings;
                    });                                                    
                    
                }
            }     
            
            const hoursLongHeight = hoursLong ? (brushesHeight - (openingsY - (storyDimensions.containerHeight - brushesHeight)/2) - 60 * sizeMultiplier):0;

            function hideScrollIndicator(){
                const hoursContentContainer =  document.getElementById("hours-content-container");
                const scrollIndicator =  document.getElementById("scroll-indicator");
                if (hoursContentContainer && scrollIndicator && hoursContentContainer.scrollHeight === hoursLongHeight){
                    scrollIndicator.style.visibility = "hidden";
                }
            }

            return (
                <div className="hours-container" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}}>
                    <div id="gallery-overlay" className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0}}/>
                    <div className="hours-file">
                        <Image alt="hours background" source={hoursLong ? "/images/hours_long.png" : "/images/hours.png"} onLoad={()=>{setShowTexts(true);if (hoursLong) hideScrollIndicator();}} style={{width: brushesWidth, height: brushesHeight, }} tintColor={props.story.primaryColor} onClick={(e)=>{e.stopPropagation();}}/>            
                    </div>
                    <div className="hours-title" style={{top: titleY, fontSize: 36 * sizeMultiplier, visibility: showTexts ? 'visible' : 'hidden'}}>
                        {t('Hours')}
                    </div>
                    <img className="hours-separator" alt="" src="/images/hours_separator.png" style={{top: separatorY, width: separatorWidth, height: separatorHeight, visibility: showTexts ? 'visible' : 'hidden'}}/>
                    <div id="hours-content-container" className={hoursLong ? "hours-scrollable-container" : ""} style={{position: "absolute", top: hoursLong ? openingsY : 0, height: hoursLong ? hoursLongHeight : "", width: "100%"}} onClick={(e)=>{e.stopPropagation();}}>
                        <table className="hours-openings" style={{top: hoursLong ? 0 : openingsY, left : hoursLong ? "52%" : "52%", /*position: hoursLong ? "":"absolute",*//*height: brushesHeight - openingsY,*/ fontSize: sizeMultiplier === 1 ? hoursFontSize : hoursFontSize * sizeMultiplier * 0.7, visibility: showTexts ? 'visible' : 'hidden' }}>
                            <tbody>                                
                                {weekDayOpenings}
                                {secondaryWeekDayOpenings}
                                {showExtendedHoursInfo && <div style={{marginTop: 10, fontSize: 14, width: "100%", fontStyle: 'italic', textAlign: 'center'}}>{t("HoursInfoExtended")}</div>}
                            </tbody>
                        </table>                        
                    </div>
                    {hoursLong && <div id="scroll-indicator" style={{position: "absolute", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", top: 2 + openingsY + hoursLongHeight, width: "100%", textAlign: "center", zIndex: 20}} onClick={(e)=>{e.stopPropagation();}}>
                        {t('Scroll')}<KeyboardDoubleArrowDownIcon width="24" height="24"/>
                    </div>}
                    
                    <CloseButton onClick={close} color={props.story.primaryColor}  width={closeWidth} height={closeHeight} className="hours-close" style={{top: closeBtnY, left: closeBtnX}} />
                    <img className="google" alt="google" src="/images/google_on_non_white.png" style={{top: googleY, width: 29 * sizeMultiplier, height: 9 * sizeMultiplier, pointerEvents: 'none'}}/>
                </div>
            );
        }
        else {                
            if (props.placeDetails != null && props.placeDetails.currentOpeningHours != null){                  
                return (
                    <SecondaryButton buttonType={hoursType} open={open}/>
                );
            }
            else {
                return null;
            }
        }
        }
        else {
            return null;
        }
    }

export const hoursLyon = {
    "open_now":false,
    "periods":[
       {
          "close":{
             "day":0,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":0,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":1,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":1,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":2,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":2,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":3,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":3,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":4,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":4,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":5,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":5,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":6,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":6,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       }
    ],
    "weekday_text":[
       "lundi: 11:30 – 22:30",
       "mardi: 11:30 – 22:30",
       "mercredi: 11:30 – 22:30",
       "jeudi: 11:30 – 22:30",
       "vendredi: 11:30 – 23:00",
       "samedi: 11:30 – 23:00",
       "dimanche: 11:30 – 22:30"
    ]
 };

 export const hoursLyonEn = {
   "open_now":false,   
   "weekday_text":[
      "Monday: 11:30 AM – 10:30 PM",
      "Thuesday: 11:30 AM – 10:30 PM",
      "Wednesday: 11:30 AM – 10:30 PM",
      "Thursday: 11:30 AM – 10:30 PM",
      "Friday: 11:30 AM – 11:00 PM",
      "Saturday: 11:30 AM – 11:00 PM",
      "Sunday: 11:30 AM – 10:30 PM"
   ]
};

 export const hoursBordeaux = {
    "open_now":false,
    "periods":[
       {
          "close":{
             "day":0,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":0,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":0,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":0,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       },
       {
          "close":{
             "day":1,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":1,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":1,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":1,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       },
       {
          "close":{
             "day":2,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":2,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":2,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":2,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       },
       {
          "close":{
             "day":3,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":3,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":3,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":3,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       },
       {
          "close":{
             "day":4,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":4,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":4,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":4,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       },
       {
          "close":{
             "day":5,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":5,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":5,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":5,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       },
       {
          "close":{
             "day":6,
             "time":"1430",
             "hours":14,
             "minutes":30
          },
          "open":{
             "day":6,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":6,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":6,
             "time":"1830",
             "hours":18,
             "minutes":30
          }
       }
    ],
    "weekday_text":[
       "lundi: 11:30 – 14:30, 18:30 – 22:30",
       "mardi: 11:30 – 14:30, 18:30 – 22:30",
       "mercredi: 11:30 – 14:30, 18:30 – 22:30",
       "jeudi: 11:30 – 14:30, 18:30 – 22:30",
       "vendredi: 11:30 – 14:30, 18:30 – 22:30",
       "samedi: 11:30 – 14:30, 18:30 – 22:30",
       "dimanche: 11:30 – 14:30, 18:30 – 22:30"
    ]
 };

 export const hoursBordeauxEn = {
   "weekday_text":[
      "Monday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM",
      "Tuesday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM",
      "Wednesday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM",
      "Thursday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM",
      "Friday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM",
      "Saturday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM",
      "Sunday: 11:30 AM – 2:30 PM, 6:30 – 10:30 PM"]
};

 export const hoursStrasbourg = {
    "open_now":false,
    "periods":[
       {
          "close":{
             "day":0,
             "time":"2245",
             "hours":22,
             "minutes":45
          },
          "open":{
             "day":0,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":1,
             "time":"2245",
             "hours":22,
             "minutes":45
          },
          "open":{
             "day":1,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":2,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":2,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":3,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":3,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":4,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":4,
             "time":"1100",
             "hours":11,
             "minutes":0
          }
       },
       {
          "close":{
             "day":5,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":5,
             "time":"1100",
             "hours":11,
             "minutes":0
          }
       },
       {
          "close":{
             "day":6,
             "time":"2300",
             "hours":23,
             "minutes":0
          },
          "open":{
             "day":6,
             "time":"1100",
             "hours":11,
             "minutes":0
          }
       }
    ],
    "weekday_text":[
       "lundi: 11:30 – 22:45",
       "mardi: 11:30 – 22:45",
       "mercredi: 11:30 – 23:00",
       "jeudi: 11:30 – 23:00",
       "vendredi: 11:00 – 23:00",
       "samedi: 11:00 – 23:00",
       "dimanche: 11:00 – 23:00"
    ]
 };

 export const hoursStrasbourgEn = {
   "weekday_text":[
      "monday: 11:30 AM – 10:45 PM",
      "tuesday: 11:30 AM – 10:45 PM",
      "wednesday: 11:30 AM – 11:00 PM",
      "thursday: 11:30 AM – 11:00 PM",
      "friday: 11:00 AM – 11:00 PM",
      "saturday: 11:00 AM – 11:00 PM",
      "sunday: 11:00 AM – 11:00 PM"
   ]   
};

 export const hoursParis = {
    "open_now":false,
    "periods":[
       {
          "close":{
             "day":0,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":0,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":1,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":1,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":2,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":2,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":3,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":3,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":4,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":4,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":5,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":5,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       },
       {
          "close":{
             "day":6,
             "time":"2230",
             "hours":22,
             "minutes":30
          },
          "open":{
             "day":6,
             "time":"1130",
             "hours":11,
             "minutes":30
          }
       }
    ],
    "weekday_text":[
       "lundi: 11:30 – 22:30",
       "mardi: 11:30 – 22:30",
       "mercredi: 11:30 – 22:30",
       "jeudi: 11:30 – 22:30",
       "vendredi: 11:30 – 22:30",
       "samedi: 11:30 – 22:30",
       "dimanche: 11:30 – 22:30"
    ]
 };

 export const hoursParisEn = {
   "weekday_text":[
      "monday: 11:30 AM – 10:30 PM",
      "tuesday: 11:30 AM – 10:30 PM",
      "wednesday: 11:30 AM – 10:30 PM",
      "thursday: 11:30 AM – 10:30 PM",
      "friday: 11:30 AM – 10:30 PM",
      "saturday: 11:30 AM – 10:30 PM",
      "sunday: 11:30 AM – 10:30 PM"
   ]
};

export default HoursButton;