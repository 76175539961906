import React from "react";
import styles from "./InputDesign.module.css";

const PagesSidebar = ({
  pages,
  selectedPage,
  setSelectedPage,
  addPage,
  deletePage,
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleDragEnd,
  draggedOverPage,
  toggleStyleEditor,
  showStyle,
}) => {
  return (
    <aside className={styles.sidebar}>
      <div style={{ margin: "0 -24px 0px -24px", padding: "0 24px 16px 24px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#1d192b" }}>
            Menu Restaurant X
          </h2>
          <div className={styles.flexGap}>
            <button
              onClick={toggleStyleEditor}
              className={styles.primaryButton}
              style={{ width: "fit-content" }}
            >
              Éditer le style
            </button>
            <button onClick={addPage} className={styles.primaryButton}>
              Add a page
            </button>
          </div>
        </div>
      </div>
      <div style={{ margin: "0 -24px 24px -24px", borderBottom: "1px solid #b2ede7" }}></div>
      <div className={styles.flexFlexColGap}>
        {pages.map((page, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragEnd={handleDragEnd}
            className={`${styles.flexJustifyBetweenItemsCenter} ${
              selectedPage === index ? "bg-gray-100" : ""
            } ${draggedOverPage === index ? "border-dashed" : ""}`}
            style={{
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #e5e7eb",
              cursor: "grab",
              backgroundColor:
                selectedPage === index ? "#f3f4f6" : "transparent",
              borderStyle: draggedOverPage === index ? "dashed" : "solid",
              opacity: draggedOverPage === index ? "0.5" : "1",
              transform: draggedOverPage === index ? "scale(1.02)" : "none",
              transition: "all 0.2s ease",
            }}
            onClick={() => setSelectedPage(index)}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span style={{ cursor: "grab", color: "#9ca3af", userSelect: "none" }}>☰</span>
              <span>
                {page.pageType.fr || page.pageType.en || `Page ${index + 1}`}
              </span>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deletePage(index);
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#ef4444",
                cursor: "pointer",
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </aside>
  );
};

export default PagesSidebar;
