import React, { useState, useRef } from "react";
import styles from "./InputDesign.module.css";
import PageEntry from "./PageEntry";

const PageContent = ({
  selectedPage,
  pages,
  updatePages,
  addPageEntry,
  deletePageEntry,
  addEntry,
  deleteEntry,
  translatePage,
  saveChanges,
  isSaving,
  setSelectedPage,
  setPages,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState("fr");
  const [isUploading, setIsUploading] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const fileInputRef = useRef();

  if (selectedPage === null || !pages[selectedPage]) {
    return null;
  }

  const handlePageTypeChange = (value) => {
    const updatedPages = [...pages];
    updatedPages[selectedPage].pageType[currentLanguage] = value;
    updatePages(updatedPages);
  };

  const updatePageEntry = (pageEntryIndex, updatedPageEntry) => {
    const updatedPages = [...pages];
    updatedPages[selectedPage].pageEntries[pageEntryIndex] = updatedPageEntry;
    updatePages(updatedPages);
  };

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === "fr" ? "en" : "fr");
  };

  const handleImageUpload = async (file) => {
    if (!file) return;

    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Erreur lors du téléchargement');
      }

      const data = await response.json();
      const updatedPages = [...pages];
      updatedPages[selectedPage].pageImgUrl = data.url;
      updatePages(updatedPages);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Erreur lors du téléchargement de l\'image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDragStart = (pageEntryIndex) => {
    setDraggedIndex(pageEntryIndex);
  };

  const handleDragOver = (e, pageEntryIndex) => {
    e.preventDefault();
    const draggedItem = pages[selectedPage].pageEntries[draggedIndex];
    if (draggedItem && draggedIndex !== pageEntryIndex) {
      const updatedPages = [...pages];
      const updatedEntries = [...updatedPages[selectedPage].pageEntries];
      updatedEntries.splice(draggedIndex, 1);
      updatedEntries.splice(pageEntryIndex, 0, draggedItem);
      updatedPages[selectedPage].pageEntries = updatedEntries;
      setPages(updatedPages);
      setDraggedIndex(pageEntryIndex);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  return (
    <article className={styles.pageContent}>
      <div className={styles.pageContentHeader}>
        <div className={styles.flexFlexColGap}>
          <h2
            style={{ fontSize: "24px", fontWeight: "bold", color: "#1d192b" }}
          >
            Page Content
          </h2>
          <div className={styles.flexGap}>
            <button onClick={addPageEntry} className={styles.primaryButton}>
              Add Entry
            </button>
            <button onClick={translatePage} className={styles.primaryButton}>
              Translate
            </button>
            <button onClick={toggleLanguage} className={styles.primaryButton}>
              {currentLanguage === "fr" ? "🇬🇧" : "🇫🇷"}
            </button>
          <button
            onClick={saveChanges}
            disabled={isSaving}
              className={styles.primaryButton}
          >
            <span>{isSaving ? "Saving..." : "Save Changes"}</span>
            {isSaving && (
              <svg
                className={styles.spinnerContainer}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className={styles.opacity25}
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className={styles.opacity75}
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            )}
          </button>
            <button onClick={() => setSelectedPage(null)} className={styles.primaryButton}>
              ✕
            </button>
          </div>
        </div>
      </div>
      <div className={styles.flex1OverflowYAuto}>
        <div className={styles.flexFlexColGap24}>
          <div className={styles.flexFlexColGap}>
            <div className={styles.flexGap} style={{ alignItems: "center" }}>
              <input
                value={pages[selectedPage].pageType[currentLanguage] || ""}
                onChange={(e) => handlePageTypeChange(e.target.value)}
                placeholder={currentLanguage === "fr" ? "Titre en français" : "English Title"}
                className={styles.inputField}
                style={{ maxWidth: "300px" }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e.target.files[0])}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <button
                onClick={() => fileInputRef.current?.click()}
                className={styles.primaryButton}
                style={{ 
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "fit-content"
                }}
              >
                {isUploading ? (
                  <span>Téléchargement...</span>
                ) : (
                  <>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                      <circle cx="8.5" cy="8.5" r="1.5" />
                      <polyline points="21 15 16 10 5 21" />
                    </svg>
                    {pages[selectedPage].pageImgUrl && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          const updatedPages = [...pages];
                          updatedPages[selectedPage].pageImgUrl = "";
                          updatePages(updatedPages);
                        }}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          color: "#ef4444",
                          cursor: "pointer",
                          padding: "0 4px",
                        }}
                      >
                        ×
                      </button>
                    )}
                  </>
                )}
              </button>
              {pages[selectedPage].pageImgUrl && (
                <img
                  src={pages[selectedPage].pageImgUrl}
                  alt=""
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              )}
            </div>
          </div>

          {pages[selectedPage].pageEntries.map((pageEntry, pageEntryIndex) => (
            <PageEntry
              key={pageEntryIndex}
              pageEntry={pageEntry}
              pageEntryIndex={pageEntryIndex}
              deletePageEntry={deletePageEntry}
              addEntry={addEntry}
              deleteEntry={deleteEntry}
              updatePageEntry={updatePageEntry}
              currentLanguage={currentLanguage}
              pages={pages}
              setPages={setPages}
              selectedPage={selectedPage}
              onDragStart={() => handleDragStart(pageEntryIndex)}
              onDragOver={(e) => handleDragOver(e, pageEntryIndex)}
              onDrop={handleDrop}
              onDragEnd={handleDragEnd}
              isDragging={draggedIndex === pageEntryIndex}
            />
          ))}
        </div>
      </div>
    </article>
  );
};

export default PageContent;
