import React from "react";
import styles from "./InputDesign.module.css";

const PreviewArea = () => {
  return (
    <section className={styles.previewArea}>
      <span className={styles.previewText}>Preview Area</span>
    </section>
  );
};

export default PreviewArea;
