import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="privacy-policy" style={{
            maxWidth: '800px',
            margin: '0 auto',
            padding: '40px 20px',
            lineHeight: '1.6'
        }}>
            <h1 style={{ 
                fontSize: '2em', 
                marginBottom: '30px',
                textAlign: 'center'
            }}>Politique de confidentialité</h1>

            <section style={{ marginBottom: '30px' }}>
                <h2 style={{ marginBottom: '20px' }}>1. Généralités</h2>
                <p>UpReality attache une grande importance à la protection et au respect de vos données personnelles et de votre vie privée.</p>
                <p>La présente politique de protection des données personnelles et vie privée, ci-après "Politique" présente la manière dont nous traitons les données à caractère personnel que UpReality recueille et/ou qui nous sont communiquées par vous à l'occasion des relations mise en oeuvre à partir de notre app/site internet ou découlant des relations contractuelles nous liant.</p>
                <p>Par la présente, UpReality, vous informe de nos pratiques concernant la collecte, l'utilisation, le stockage et le partage éventuel des données que nous recueillons.</p>
                <p>Nous vous invitons à lire attentivement le présent document pour connaître et comprendre les pratiques que nous mettons en œuvre quant aux traitements de vos données.</p>
                <p>Cette Politique s'applique en complément des dispositions des CGU de notre site/app. Ce document est accessible depuis <a href="https://upreality.store/terms/cgu.pdf" style={{ color: '#0066cc' }}>https://upreality.store/terms/cgu.pdf</a></p>
            </section>

            <section style={{ marginBottom: '30px' }}>
                <h2 style={{ marginBottom: '20px' }}>2. Finalité du traitement de vos données personnelles</h2>
                <p>Afin de proposer à nos clients un accès à nos produits et services, et pour nous permettre d'évaluer et d'améliorer ces produits et services, nous sommes amenés à collecter des données vous concernant.</p>
                <p>Dans ce cadre, nous vous informons que vos données font l'objet d'un traitement automatisé, basé sur l'acceptation de la présente politique, aux fins suivantes :</p>
                <p>Nous utilisons les données que nous recueillons afin de permettre</p>
                <ul style={{ marginLeft: '20px', marginTop: '10px' }}>
                    <li>de diffuser sur le web les contenus que vous créez depuis le site/l'app : textes, photos et vidéos</li>
                    <li>d'agréger des statistiques d'utilisation de vos contenus pour vous les retransmettre afin que vous puissiez mesurer l'efficacité de notre site/app</li>
                    <li>l'amélioration du site/de l'app par la collecte de statistiques sur l'utilisation que vous en faites et d'informations sur les erreurs rencontrées</li>
                </ul>
            </section>

            <section style={{ marginBottom: '30px' }}>
                <h2 style={{ marginBottom: '20px' }}>3. Données que nous recueillons</h2>
                <h3 style={{ marginBottom: '15px' }}>a) Transmises directement</h3>
                <p>Uniquement pour le restaurateur ou son représentant, sur l'éditeur de story (ne concerne donc pas le client final sur le lecteur de story) : email, photos, vidéos, type d'établissement, quartier, no de téléphone, liens vers plateformes de réservation ou commande, identifiant Instagram ou Facebook, identifiant google place, document de menu et textes faisant partie de votre présentation vidéo interactive (et autres données techniques en permettant la restitution sur d'autres appareils).</p>
                
                <h3 style={{ marginTop: '20px', marginBottom: '15px' }}>b) Transmises automatiquement</h3>
                <ul style={{ marginLeft: '20px' }}>
                    <li>Statistiques de consultation de vos clients sur vos présentations vidéos interactives</li>
                    <li>Statistiques d'usage via les produits Firebase / Google Analytics / Facebook / TikTok</li>
                    <li>Données d'erreur via l'outil Sentry</li>
                </ul>
            </section>

            <section style={{ marginBottom: '30px' }}>
                <h2 style={{ marginBottom: '20px' }}>4. Durée de conservation de vos données</h2>
                <p>Vos données sont stockées pour une durée de 3 ans à partir de votre dernière activité sur l'app. Vous pouvez à tout moment en demander, le retrait, la modification ou la restitution en nous contactant à l'adresse <a href="mailto:contact@upreality.store" style={{ color: '#0066cc' }}>contact@upreality.store</a></p>
            </section>

            <section style={{ marginBottom: '30px' }}>
                <h2 style={{ marginBottom: '20px' }}>5. Partage des données collectées à des tiers et fins</h2>
                <ul style={{ marginLeft: '20px' }}>
                    <li>Firebase / Google Analytics / Facebook / TikTok pour la collecte des statistiques d'utilisation sur le site/l'app (données anonymisées)</li>
                    <li>Sentry pour la collecte de données d'analyse d'erreur rencontrées sur le site/l'app</li>
                    <li>Facebook pour l'accès aux fonctionnalités Login, récupération de contenu du profil et messagerie Instagram. Facebook peut collecter et recevoir des informations du site/de l'app et d'autres et utiliser ces informations pour fournir des services de mesure et de publicité ciblées. Pour se désinscrire de ces services, utiliser les paramètres Facebook de votre compte.</li>
                </ul>
            </section>
        </div>
    );
};

export default PrivacyPolicy;