"use client";
import React, { useState } from "react";
import styles from "./InputDesign.module.css";
import ColorInput from "./ColorInput";
import FontInput from "./FontInput";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function StyleEditor({ styleSettings, setStyleSettings, toggleStyleEditor }) {
  const [showJsonDialog, setShowJsonDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const updateStyleSetting = (key, value) => {
    setStyleSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateFontSetting = (fontType, property, value) => {
    setStyleSettings((prev) => ({
      ...prev,
      [fontType]: {
        ...prev[fontType],
        [property]: value,
      },
    }));
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowJsonDialog(true);
    } catch (error) {
      alert("Erreur lors de la sauvegarde");
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseDialog = () => {
    setShowJsonDialog(false);
  };

  return (
    <div className={styles.styleEditor}>
      <div className={styles.styleEditorHeader}>
        <div className={styles.flexFlexColGap}>
          <h2 className={styles.styleEditorTitle}>Style Editor</h2>
          <div className={styles.flexGap}>
            <button 
              onClick={handleSaveChanges} 
              className={styles.primaryButton}
              disabled={isSaving}
            >
              <span>{isSaving ? "Saving..." : "Save Changes"}</span>
              {isSaving && (
                <svg
                  className={styles.spinnerContainer}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className={styles.opacity25}
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className={styles.opacity75}
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              )}
            </button>
            <button onClick={toggleStyleEditor} className={styles.primaryButton}>
              ✕
            </button>
          </div>
        </div>
      </div>
      <div className={styles.styleEditorContent}>
        <FontInput
          label="Title Font"
          nameValue={styleSettings.titleFont.name}
          locationValue={styleSettings.titleFont.location}
          onNameChange={(value) =>
            updateFontSetting("titleFont", "name", value)
          }
          onLocationChange={(value) =>
            updateFontSetting("titleFont", "location", value)
          }
        />

        <FontInput
          label="Text Font"
          nameValue={styleSettings.textFont.name}
          locationValue={styleSettings.textFont.location}
          onNameChange={(value) => updateFontSetting("textFont", "name", value)}
          onLocationChange={(value) =>
            updateFontSetting("textFont", "location", value)
          }
        />

        <div className={styles.colorInputsGrid}>
          <div className={styles.colorInputColumn}>
            <ColorInput
              label="Background Color"
              value={styleSettings.backgroundColor}
              onChange={(value) => updateStyleSetting("backgroundColor", value)}
            />

            <ColorInput
              label="Text Color"
              value={styleSettings.textColor}
              onChange={(value) => updateStyleSetting("textColor", value)}
            />

            <ColorInput
              label="Price Color"
              value={styleSettings.priceColor}
              onChange={(value) => updateStyleSetting("priceColor", value)}
            />

            <ColorInput
              label="Close Color"
              value={styleSettings.closeColor}
              onChange={(value) => updateStyleSetting("closeColor", value)}
            />
          </div>

          <div className={styles.colorInputColumn}>
            <ColorInput
              label="Detail Background Color"
              value={styleSettings.detailBackgroundColor}
              onChange={(value) =>
                updateStyleSetting("detailBackgroundColor", value)
              }
            />

            <ColorInput
              label="Detail Text Color"
              value={styleSettings.detailTextColor}
              onChange={(value) => updateStyleSetting("detailTextColor", value)}
            />

            <ColorInput
              label="Detail Description Color"
              value={styleSettings.detailDescColor}
              onChange={(value) => updateStyleSetting("detailDescColor", value)}
            />

            <ColorInput
              label="Detail Price Color"
              value={styleSettings.detailPriceColor}
              onChange={(value) => updateStyleSetting("detailPriceColor", value)}
            />

            <ColorInput
              label="Detail Close Color"
              value={styleSettings.detailCloseColor}
              onChange={(value) => updateStyleSetting("detailCloseColor", value)}
            />
          </div>
        </div>
      </div>

      <Dialog open={showJsonDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Style Settings</DialogTitle>
        <DialogContent>
          <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(styleSettings, null, 2)}
          </pre>
        </DialogContent>
        <DialogActions>
          <button onClick={handleCloseDialog} className={styles.primaryButton}>
            Fermer
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default StyleEditor;
