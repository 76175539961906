import './../../Player.css';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import { browserLocales, openFullscreen, weekdayTextToGroupedByDaysOpeningHours } from '../../Utils';
import { analyticsManager } from '../../../index';
import SecondaryButton, {hoursType} from '../components/SecondaryButton';
import CloseButton from '../components/CloseButton';
import { useAnimate } from 'framer-motion';
import { hoursBordeaux, hoursBordeauxEn, hoursLyon, hoursLyonEn, hoursParis, hoursParisEn, hoursStrasbourg, hoursStrasbourgEn } from './HoursButton';
import ColorContrastChecker from 'color-contrast-checker';

function HoursButtonNEW(props){
    const [isOpened, setIsOpened] = useState(false);

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");
    const sizeMultiplier = useContext(PlayerContext).get("sizeMultiplier");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");
    const [showTexts, setShowTexts] = useState(false);
    const idPrefix = fromDemo ? (fromDemo +"-") : "";

    // TODO Hours with no secondary hours

    const { t } = useTranslation();    
    const closeWidth = 42 * sizeMultiplier;
    const closeHeight = 40 * sizeMultiplier;  

    const closeBtnX =  storyDimensions.containerWidth - closeWidth - 8 * sizeMultiplier;
    const closeBtnY = storyDimensions.containerHeight - closeHeight - 8 * sizeMultiplier;

    const [scope, animate] = useAnimate();

    useEffect(() => {
        if (props.story && props.show && isOpened && activeFeature === "hours"){   
            const galleryOverlay = document.getElementById(idPrefix+"gallery-overlay");
            if (galleryOverlay != null){                                  
                const animation = async () => {                       
                    animate(galleryOverlay, { opacity: 1}, {duration: 0.3});                    
                }
                
                animation();
            }                        
        }
    }, [props.story, props.show, isOpened, activeFeature]);

    if (props.story.posId == null) return (null);

    function cutMultipleHours(hourText){
        const language = browserLocales(true)[0] === "fr" ? "fr" : "en";
        if (language === "fr"){
            return hourText;
        }
        else {
            const splittedLine = hourText.split(",");         
            return splittedLine.map((line)=>{            
                let carriageReturn = false;
                if (splittedLine.indexOf(line) > 0){
                    carriageReturn = true;
                }
                return <>{carriageReturn && <br/>}{line.trim()}</>;
            });
        }
    }

    const open = () => {                
        if (!fromMaker /*&& !fromDemo*/ && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){                        
            const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                try {
                    ambiantPlayer.play();                
                } catch(error){}
            }
            setPlayAudio(true);            
        }
        pauseVideo();
        openFullscreen();
        setActiveFeature("hours");
        setIsOpened(true);
      
        analyticsManager.logCheckHoursTag(props.storyId, playMode, true);
                
        if (appAnalyticsManager)
            appAnalyticsManager.clickedHours();

        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "hours",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "hours"});
        }
      };

      const close = () => {        
        propagateFocusChange(true);
        setIsOpened(false);
        setActiveFeature(null);
      };        
    
      let showExtendedHoursInfo = false;
      if (props.show){   
        const language = browserLocales(true)[0] === "fr" ? "fr" : "en";
        if (props.storyId === "LnSX2qKrthKsRmr1vcxc" && props.placeDetails){
            if (language === "fr"){
                props.placeDetails.currentOpeningHours = hoursLyon;
            }
            else {
               props.placeDetails.currentOpeningHours = hoursLyonEn;
            }
            showExtendedHoursInfo = true;
        }
        if (props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" && props.placeDetails){
         if (language === "fr"){
            props.placeDetails.currentOpeningHours = hoursStrasbourg;
         }
         else {
            props.placeDetails.currentOpeningHours = hoursStrasbourgEn;
         }
         showExtendedHoursInfo = true;
         }
         if (props.storyId === "zUEpyuVwo5Tws3hnvFds" && props.placeDetails){
            if (language === "fr"){
               props.placeDetails.currentOpeningHours = hoursBordeaux;
            }
            else {
               props.placeDetails.currentOpeningHours = hoursBordeauxEn;
            }
            showExtendedHoursInfo = true;
        }
        if (props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" && props.placeDetails){
         if (language === "fr"){
            props.placeDetails.currentOpeningHours = hoursParis;
         }
         else {
            props.placeDetails.currentOpeningHours = hoursParisEn;
         }
         showExtendedHoursInfo = true;
         }                 

            if (isOpened && activeFeature === "hours"){                
                let hoursFontSize = 22;
                let weekDayOpenings = null;
                let secondaryWeekDayOpenings = "";
                
                const weekDayOpeningsTable = weekdayTextToGroupedByDaysOpeningHours(props.placeDetails.currentOpeningHours.weekdayDescriptions);

                if (weekDayOpeningsTable.format === "day"){
                    hoursFontSize = 18;                    
                    weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                    <>
                        <tr/><tr/>
                        <tr key={weekDayOpening}><td style={{textAlign: 'center'}}>{
                            cutMultipleHours(weekDayOpening)
                            }</td></tr>
                    </>
                    );
                }
                else if (weekDayOpeningsTable.format === "grouped"){                    
                    hoursFontSize = 22;
                    weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                        {                                             
                            if (weekDayOpening.startingDay === weekDayOpening.endingDay){
                                return (<><tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                               
                                    weekDayOpening.startingDay} 
                                </td></tr>
                                <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>                               
                                    <i>{cutMultipleHours(weekDayOpening.hours)}</i>                        
                                    </td></tr><tr/><tr/>
                                <tr/><tr/></>);
                            }
                            else {
                                return (
                                <>
                                <tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                                
                                    t('HoursFromTo', {from: weekDayOpening.startingDay, to: weekDayOpening.endingDay})                         
                                    }</td></tr>
                                <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>{                                
                                    cutMultipleHours(weekDayOpening.hours)                        
                                    }</td></tr><tr/><tr/>
                                </>);
                            }                                                    
                        });
                    
                }
                if (props.placeDetails.currentSecondaryOpeningHours){                        
                    secondaryWeekDayOpenings = props.placeDetails.currentSecondaryOpeningHours.map((openingHours)=>{
                        const weekDayOpeningsTable = weekdayTextToGroupedByDaysOpeningHours(openingHours.weekdayDescriptions);                            
                        const weekDayOpenings = weekDayOpeningsTable.openingHours.map((weekDayOpening) =>
                            {           
                                const title = weekDayOpening === weekDayOpeningsTable.openingHours[0] ?<><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr/><tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center', fontWeight: "bold"}}>{t(openingHours.secondaryHoursType)}</td></tr></> : "";                                                                                 
                                if (weekDayOpening.startingDay === weekDayOpening.endingDay){
                                    return (<>{title}<tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                               
                                        weekDayOpening.startingDay} 
                                    </td></tr>
                                    <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>                               
                                        <i>{cutMultipleHours(weekDayOpening.hours)}</i>                        
                                        </td></tr><tr/><tr/>
                                    <tr/><tr/></>);
                                }
                                else {
                                    return (
                                    <>{title}
                                    <tr key={weekDayOpening.startingDay}><td style={{textAlign: 'center'}}>{                                
                                        t('HoursFromTo', {from: weekDayOpening.startingDay, to: weekDayOpening.endingDay})                         
                                        }</td></tr>
                                    <tr key={weekDayOpening.startingDay+"bis"}><td style={{textAlign: 'center', fontStyle: 'italic'}}>{                                
                                        cutMultipleHours(weekDayOpening.hours)                        
                                        }</td></tr><tr/><tr/>
                                    </>);
                                }                                                    
                            });    
                        return weekDayOpenings;
                    });                                                    
                    
                }      
                
                const additionalStyle = {};
                let logoMarginTop = 32 * sizeMultiplier;
                if (weekDayOpeningsTable.format === "grouped" && !props.placeDetails.currentSecondaryOpeningHours){                    
                    additionalStyle.display = "flex";
                    additionalStyle.flexDirection = "column";
                    additionalStyle.justifyContent = "center";
                    additionalStyle.alignItems = "center";
                    additionalStyle.alignContent = "center";     
                    logoMarginTop = -72 * sizeMultiplier;               
                };

                const logo = props.story.hoursLogo ? props.story.hoursLogo : props.story.avatarUrl ? props.story.avatarUrl : "/images/avatar_placeholder.png";
                const logoStyle = {
                    zIndex: 20
                };
                if (props.story.hoursLogo){                    
                    logoStyle.maxWidth = storyDimensions.containerWidth - 100 * sizeMultiplier;
                    logoStyle.maxHeight = 96 * sizeMultiplier;                    
                }
                else {
                    logoStyle.width = 96 * sizeMultiplier;    
                    logoStyle.height = 96 * sizeMultiplier;
                    logoStyle.borderRadius = 50;
                }

                let textColor = props.story.primaryColor;

                const ccc = new ColorContrastChecker();

                if (!ccc.isLevelCustom(props.story.primaryColor, "#FFFFFF", 3)) {
                    if (!ccc.isLevelCustom(props.story.secondaryColor, "#FFFFFF", 3)) {
                        textColor = "#000000";
                    }
                    else {                        
                        textColor = props.story.secondaryColor;
                    }
                } 

                return (
                    <div className="hours-container hours-scrollable-container" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, ...additionalStyle}}>
                        <div id={idPrefix + "gallery-overlay"} className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0, background: "#FFFFFFD0"}}/>
                        <div style={{marginTop: logoMarginTop, width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', zIndex: 20}} onClick={(e)=>{e.stopPropagation();}}>
                            <img src={logo} style={{...logoStyle}} onLoad={()=>{setShowTexts(true);}} />
                        </div>
                        <div style={{marginTop: (16+2) * sizeMultiplier, fontSize: 36 * sizeMultiplier, color: textColor, fontWeight: 'bolder', position: "relative", zIndex: 20, width: "100%", textAlign: "center", visibility: showTexts ? 'visible' : 'hidden'}}>
                            {t('Hours')}
                        </div>  
                        {/*<div  style={{marginTop: (4) * sizeMultiplier, fontSize: 16 * sizeMultiplier, fontStyle: 'italic', color: textColor + "D0", position: "relative", zIndex: 20, width: "100%", textAlign: "center", visibility: showTexts ? 'visible' : 'hidden'}}>
                            {t('HoursSubtitle')}
                        </div>*/}                      
                        <div id="hours-content-container" style={{marginTop: 16, width: "100%", visibility: showTexts ? 'visible' : 'hidden'}} onClick={(e)=>{e.stopPropagation();}}>
                            <table className="hours-openings" style={{position: "relative", fontSize: hoursFontSize * sizeMultiplier, color: textColor + "D0", width: storyDimensions.containerWidth - 60 * sizeMultiplier}}>
                                <tbody>                                
                                    {weekDayOpenings}
                                    {secondaryWeekDayOpenings}
                                    {showExtendedHoursInfo && <div style={{marginTop: 10 * sizeMultiplier, fontSize: 14 * sizeMultiplier, width: "100%", fontStyle: 'italic', textAlign: 'center'}}>{t("HoursInfoExtended")}</div>}
                                </tbody>
                            </table>                        
                        </div>
                        
                        {<CloseButton onClick={close} color={props.story.primaryColor} width={closeWidth} height={closeHeight} className="hours-close" style={{top: closeBtnY, left: closeBtnX, visibility: showTexts ? 'visible' : 'hidden'}} />}
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img className="google" alt="google" src="/images/google_on_white.png" style={{marginTop: 16 * sizeMultiplier, marginBottom: 16 * sizeMultiplier, position: 'relative', width: 38 * sizeMultiplier, height: 12 * sizeMultiplier, pointerEvents: 'none', visibility: showTexts ? 'visible' : 'hidden'}}/>
                        </div>
                    </div>
                );
            }
            else {                
                if (props.placeDetails != null && props.placeDetails.currentOpeningHours != null){                  
                    return (
                        <SecondaryButton buttonType={hoursType} open={open}/>
                    );
                }
                else {
                    return null;
                }
            }
        }
        else {
            return null;
        }
    }

export default HoursButtonNEW;