import React from "react";
import styles from "./TestimonialsSection.module.css";

const ProfileSection = ({ name, role, avatar }) => {
  return (
    <div className={styles.profile}>
      <div>
        <img src={avatar} className={styles.avatar} role="img" aria-label={`${name}'s profile picture`}  alt={`${name}'s profile picture`} />
    </div>
      <div className={styles.profileInfo}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.role}>{role}</p>
      </div>
    </div>
  );
};

export default ProfileSection;