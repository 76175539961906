import React from "react";
import styles from "./TestimonialsSection.module.css";
import TestimonialCard from "./TestimonialCard";
import { useTranslation } from "react-i18next";

const TestimonialsSection = () => {

    const { t } = useTranslation();

  const testimonials = [
    {
      id: 1,
      name: "Kevin Fontaine",
      role: t("KFontaineTitle"),
      text: t("KFontaineTestimonial"),
      avatar: "/images/KFontaine.jpg",
    },    
  ];

  return (
    <>

      <section className={styles.testimonialsContainer}>
        <header className={styles.testimonialsHeader}>
          <h2 style={{fontSize: 26, fontWeight: "bold"}}>{t('TestimonialsTitle')}</h2>
          <p className={styles.subtitle}>
          {t('TestimonialsSubtitle')}
          </p>
        </header>

        <div className={styles.testimonialsGrid}>
          {testimonials.map((testimonial) => (
            <TestimonialCard
              key={testimonial.id}
              name={testimonial.name}
              role={testimonial.role}
              text={testimonial.text}
            avatar={testimonial.avatar}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default TestimonialsSection;