"use client";
import React from "react";
import MenuEditor from "./MenuEditor";

function InputDesign() {
  return (
    <>
      <MenuEditor />
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">',
          }}
        />
      </div>
    </>
  );
}

export default InputDesign;
