import './../../Player.css';
import React, {useContext, useState, useEffect, useRef} from 'react';
import { PlayerContext } from '../../PlayerContext';
import { useTranslation } from 'react-i18next';
import { menuType, insideType } from './StickerGallery';
import { getTextWidth, printObj } from '../../Utils';
import { StickerContext } from '../../StickerContext';
import { useAnimate } from "framer-motion";
import { use } from 'i18next';
import { setStoryClicked1Time } from '../../Player';
import MaterialButton from '../../../maker/component/MaterialButton';

var dragTime = 0;

function Sticker(props){

    const { t } = useTranslation();
   
    //const [touchDistFromCenter, setTouchDistFromCenter] = useState(null);
    const touchDistFromCenterRef = React.useRef(null);
    const storyRef = React.useRef(props.story);
    
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const activeDrag = useContext(StickerContext).get("activeDrag");

    const setActiveDrag = useContext(StickerContext).get("setActiveDrag");
    const setStickerDrop = useContext(StickerContext).get("setStickerDrop");
    const setInsideStickerDimensions = useContext(StickerContext).get("setInsideStickerDimensions");
    const setMenuStickerDimensions = useContext(StickerContext).get("setMenuStickerDimensions");
    const insideStickerDimensions = useContext(StickerContext).get("insideStickerDimensions");
    const menuStickerDimensions = useContext(StickerContext).get("menuStickerDimensions");
    const stickerDimensions = props.stickerType === menuType ? menuStickerDimensions : insideStickerDimensions;
    const stickersOutOfScreen = useContext(StickerContext).get("stickersOutOfScreen");
    const setStickersOutOfScreen = useContext(StickerContext).get("setStickersOutOfScreen");
    const stickersDraggable = useContext(StickerContext).get("stickersDraggable");
    const sizeMultiplier = useContext(PlayerContext).get("sizeMultiplier");
    const playMode = useContext(PlayerContext).get("playMode");

    const highlight = useContext(PlayerContext).get("highlight");
    const setPlayInteractiveAnimation = useContext(PlayerContext).get("setPlayInteractiveAnimation");
    const [scope, animate] = useAnimate(); 
    const setStickerOpeningPlayed = useContext(PlayerContext).get("setStickerOpeningPlayed");
    const stickerOpeningPlayed = useContext(PlayerContext).get("stickerOpeningPlayed");
    
    let imgSrc = "#";
    let title = "";
    let cssClass = "";
    if(props.stickerType === menuType){
        if (props.story && props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw" ){
          title = "Programme";
          imgSrc = "/images/ic_infos.png";
          cssClass = "StickerText menu";
        }  
        else if (props.story && (props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "dMS24cQaTXn47tuspxet")){
          title = t('MenusTitle');
          imgSrc = "/images/ic_restaurant_menu.png";
          cssClass = "StickerText menu"; 
        }    
        else {
          title = t('MediasGalleryTitle');
          imgSrc = "/images/ic_restaurant_menu.png";
          cssClass = "StickerText menu";        
        }
    } else if (props.stickerType === insideType) { 
      if (props.story && props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw" ){
        title = "Galerie";    
        imgSrc = "/images/ic_wb_sunny.png";    
        cssClass = "StickerText inside";
      }
      else if (props.story && (props.storyId === "32etJrIVkk0BjlVtPLG1" || props.story.baseStoryId === "32etJrIVkk0BjlVtPLG1")){          
        title = t("DishesGalleryTitle");   
        imgSrc = "/images/ic_wb_sunny.png";    
        cssClass = "StickerText inside";              
      }
      else if (props.story && props.story.promotionImgUrl){
        title = t("PromotionTitle");  
        if (props.storyId === "dMS24cQaTXn47tuspxet"){
          imgSrc = "/images/ic_celebration_caffe.png"; 
        } 
        else {
          imgSrc = "/images/ic_celebration.png";    
        }
        cssClass = "StickerText inside";
      }
      else {
        title = t('VirtualTourTitle');    
        imgSrc = "/images/ic_wb_sunny.png";    
        cssClass = "StickerText inside";
      }
    }
    const position = getStickerPosition(props.story, storyDimensions, props.stickerType);
    
    // TODO approximation
    const stickerWidth = (48 + getTextWidth(title.toUpperCase(), 22 + "px Roboto condensed")) * sizeMultiplier + (sizeMultiplier > 1 ? 2 * sizeMultiplier : 0);
    const stickerHeight = 40 * sizeMultiplier;

    useEffect(() => {
      
      if (menuType === props.stickerType){
        setMenuStickerDimensions({width: stickerWidth, height: stickerHeight});
      }
      else if (insideType === props.stickerType){
        setInsideStickerDimensions({width: stickerWidth, height: stickerHeight});
      }
     }, []);

     useEffect(() => {
      storyRef.current = props.story;
    }, [props.story]);    
   
    useEffect(() => {
        if (props.story && (!fromMaker || !stickersDraggable || activeDrag != null && activeDrag !== props.stickerType) && !stickerOpeningPlayed){    
          const stickerContainer = document.getElementById(props.stickerType);           
          if (stickerContainer != null){         
            const animation = async () => {    
              await animate(stickerContainer, { transform: "scale(1)"}, {duration: 0}); 
              if (stickerContainer != null) await animate(stickerContainer, { transform: "scale(1)"}, {duration: 2});             
              if (stickerContainer != null) await animate(stickerContainer, { transform: "scale(1.1) translateX("+ (-stickerWidth/20) + "px) translateY("+ (-stickerHeight/20) + "px)"}, {duration: 0.3});
              if (stickerContainer != null) await animate(stickerContainer, { transform: "scale(1) translateX(0px) translateY(0px)"}, {duration: 0.2});              
              setStickerOpeningPlayed(true);             
            }
            
            animation();
          }                        
        }
    }, [props.story, stickerOpeningPlayed]);

    function open(e){      
      e.stopPropagation();
      propagateFocusChange(false);
      setStoryClicked1Time(true);
      setPlayInteractiveAnimation(false);
      props.open();
    }    

    function handleDragStart(e) {      
      if(props.stickerType === menuType){
        setActiveDrag(menuType);
      } else if (props.stickerType === insideType) {
        setActiveDrag(insideType);
      }

      e.dataTransfer.effectAllowed = 'move';        
      e.dataTransfer.setData('text/plain', printObj({distXfromCenter: e.clientX - e.target.parentElement.getBoundingClientRect().left - (position.x + stickerDimensions.width/2), distYfromCenter: e.clientY - e.target.parentElement.getBoundingClientRect().top - (position.y + stickerDimensions.height/2)}));
      //log ("dataTransfer " + printObj({distXfromCenter: e.clientX - e.target.parentElement.getBoundingClientRect().left - (position.x + stickerDimensions.width/2), distYfromCenter: e.clientY - e.target.parentElement.getBoundingClientRect().top - (position.y + stickerDimensions.height/2)}));
    }

    function handleDragOver(e) {
      e.preventDefault();         

      return false;
    }

    function handleDrop(e) {      
      e.stopPropagation(); // stops the browser from redirecting.        

      const startCoordsTxt = e.dataTransfer.getData('text/plain');
      if (startCoordsTxt == null || !startCoordsTxt.startsWith("{\"distXfromCenter\"")){ 
        setActiveDrag(null);
        return false;
      }
      const startCoords = JSON.parse(startCoordsTxt);        

      let containerLeft = e.target.getBoundingClientRect().left;
      let containerTop = e.target.getBoundingClientRect().top;
      let containerWidth = e.target.getBoundingClientRect().width;
      let containerHeight = e.target.getBoundingClientRect().height;
      if (e.target.className === "Sticker maker-sticker-container-box"){
        containerLeft = e.target.parentElement.getBoundingClientRect().left;
        containerTop = e.target.parentElement.getBoundingClientRect().top;
        containerWidth = e.target.parentElement.getBoundingClientRect().width;
        containerHeight = e.target.parentElement.getBoundingClientRect().height;
      }
      else if (e.target.className === "sticker-img"){ 
        containerLeft = e.target.parentElement.parentElement.getBoundingClientRect().left;
        containerTop = e.target.parentElement.parentElement.getBoundingClientRect().top;
        containerWidth = e.target.parentElement.parentElement.getBoundingClientRect().width;
        containerHeight = e.target.parentElement.parentElement.getBoundingClientRect().height;
      }
      else if (e.target.className === cssClass){ 
        containerLeft = e.target.parentElement.parentElement.getBoundingClientRect().left;
        containerTop = e.target.parentElement.parentElement.getBoundingClientRect().top;
        containerWidth = e.target.parentElement.parentElement.getBoundingClientRect().width;
        containerHeight = e.target.parentElement.parentElement.getBoundingClientRect().height;
      }
        
      const newX = e.clientX - containerLeft - startCoords.distXfromCenter;// - stickerDimensions.width/2;
      const newY = e.clientY - containerTop - startCoords.distYfromCenter;// - stickerDimensions.height/2;

      // recalculate dimWithOffset + offset to adapt to the current player (in case of an update/delete of teh store foront on another preview player configuration)
      let dimWithOffset;
      let offsetValue;
      if (containerHeight / containerWidth > storyRef.current.height / storyRef.current.width) {
        dimWithOffset = "width";
        let trueWidthOnVideoFormat = Math.round(containerWidth * storyRef.current.height / containerHeight);
        offsetValue = storyRef.current.width - trueWidthOnVideoFormat;           
      } else {
          dimWithOffset = "height";
          let trueWidthOnVideoFormat = containerHeight * storyRef.current.width / containerWidth;
          offsetValue = Math.round(storyRef.current.height - trueWidthOnVideoFormat);           
      }      

      setStickerDrop({
        mediaType: props.stickerType,
        x: newX,
        y: newY,
        dimWithOffset: dimWithOffset,
        offsetValue: offsetValue,
      });

      setActiveDrag(null);
      
      return false;
    }    

    function handleDragEnd(e) {
      setActiveDrag(null);
      
      return false;
    }

    function onStickerTouchStart(e){      
      dragTime = new Date().getTime();
      setActiveDrag(props.stickerType);         
      const container = document.getElementById(props.stickerType + "-container"); 

      touchDistFromCenterRef.current = {
        distXfromCenter: e.touches[0].clientX - container.getBoundingClientRect().left - (position.x + stickerDimensions.width/2), 
        distYfromCenter: e.touches[0].clientY - container.getBoundingClientRect().top - (position.y + stickerDimensions.height/2),
        touchStartX: e.touches[0].clientX,
        touchStartY: e.touches[0].clientY
      };     
    }

    function onStickerTouchEnd(e){  
      // on desktop emulating mobile, may cause a double click preventing the sticker to open -> long touch to open the sticker
      if (new Date().getTime() - dragTime < 200 && Math.abs(touchDistFromCenterRef.current.touchStartX - e.changedTouches[0].clientX) < 10 && Math.abs(touchDistFromCenterRef.current.touchStartY - e.changedTouches[0].clientY) < 10){
        dragTime = 0;
        setActiveDrag(null);
        touchDistFromCenterRef.current = null;
        open(e);
        return false;
      }
      const containerLeft = document.getElementById(props.stickerType + "-container").getBoundingClientRect().left;
      const containerRight = document.getElementById(props.stickerType + "-container").getBoundingClientRect().right;
      const containerTop = document.getElementById(props.stickerType + "-container").getBoundingClientRect().top;
      const containerBottom = document.getElementById(props.stickerType + "-container").getBoundingClientRect().bottom;
      const containerWidth = document.getElementById(props.stickerType + "-container").getBoundingClientRect().width;
      const containerHeight = document.getElementById(props.stickerType + "-container").getBoundingClientRect().height;
      let newX = e.changedTouches[0].clientX/* - containerLeft*/ - touchDistFromCenterRef.current.distXfromCenter;      
      let newY = e.changedTouches[0].clientY/* - containerTop*/ - touchDistFromCenterRef.current.distYfromCenter;

      newX = Math.max(0, newX); newX = Math.min(newX, containerRight - containerLeft);
      newY = Math.max(0, newY); newY = Math.min(newY, containerBottom - containerTop);
      
      // recalculate dimWithOffset + offset to adapt to the current player (in case of an update/delete of teh store foront on another preview player configuration)
      let dimWithOffset;
      let offsetValue;
      
      if (containerHeight / containerWidth > storyRef.current.height / storyRef.current.width) {
        dimWithOffset = "width";
        let trueWidthOnVideoFormat = Math.round(containerWidth * storyRef.current.height / containerHeight);
        offsetValue = storyRef.current.width - trueWidthOnVideoFormat;           
      } else {
          dimWithOffset = "height";
          let trueWidthOnVideoFormat = containerHeight * storyRef.current.width / containerWidth;
          offsetValue = Math.round(storyRef.current.height - trueWidthOnVideoFormat);           
      }
      
      setStickerDrop({
        mediaType: props.stickerType,
        x: newX,
        y: newY,
        dimWithOffset: dimWithOffset,
        offsetValue: offsetValue,
      });            
            
      setActiveDrag(null);       
      touchDistFromCenterRef.current = null;
    }

    useEffect(() => {
      if (highlight != null){

          let elementId = null;
          if (highlight.includes("menu") && props.stickerType === menuType){
              elementId = props.stickerType;
          }
          else if (highlight.includes("inside") && props.stickerType === insideType){
              elementId = props.stickerType;
          }

          const element = document.getElementById(elementId);
          if (elementId != null && element != null){                
              const animation = async () => {                  
                  if (element != null){
                    await animate(element, { background: "#FFFFFF80"}, {duration: 0.6});
                  }
                  if (element != null)
                    animate(element, { background: "#FFFFFFFF"}, {duration: 0.6});
              }
              
              animation();
          }
      }
    }, [highlight]);

    if (props.show){
      if (!fromMaker || !stickersDraggable || activeDrag != null && activeDrag !== props.stickerType){

        function getIcon(){
          return (<img src={imgSrc} style={{width: 16 * sizeMultiplier, height: 16 * sizeMultiplier, marginLeft: sizeMultiplier >= 1 ? 4 * sizeMultiplier : 6 * sizeMultiplier, marginRight: sizeMultiplier >= 1 ? -2 * sizeMultiplier : -6 * sizeMultiplier, pointerEvents: 'none'}} />)
        }

        return (                  
          <div id={props.stickerType} style={{position: "absolute", zIndex: 10, left: position.x, top: position.y, borderRadius: '8px',backgroundColor: 'white', }}>
            <MaterialButton id={props.stickerType+"-btn"} style={"branded-white-filled"} sx={{position: "absolute", fontWeight: 'normal', fontSize: 22 * sizeMultiplier, paddingTop: 0.9, textTransform: 'uppercase'}} color="#000000" rippleColor={props.stickerType === menuType ? "#9C27B0" : "#F57C00"} icon={getIcon()} width={stickerWidth} height={stickerHeight} value={title} onClick={open} noMacSafariSpec={true}/>
          </div>
        );
      }
      else {
        return (        
          <div id={props.stickerType + "-container"} className='maker-sticker-container' onDragOver={(e)=>handleDragOver(e)} onDrop={(e)=>handleDrop(e)} style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, position: 'absolute'}}>
            <div id={props.stickerType} className="Sticker maker-sticker-container-box" draggable="true" onDragStart={(e)=>handleDragStart(e)} onDragOver={(e)=>handleDragOver(e)} onDragEnd={(e)=>handleDragEnd(e)} onTouchStart={(e)=>onStickerTouchStart(e)} onTouchEnd={(e)=>onStickerTouchEnd(e)} style={{left: position.x, top: position.y, padding: 8 * sizeMultiplier, paddingLeft : 13 * sizeMultiplier, paddingRight : 13 * sizeMultiplier, cursor: 'move'}} onClick={open}>
              <img id={props.stickerType + "-img"} className="sticker-img" draggable="false" src={imgSrc} alt={title} style={{width: 16 * sizeMultiplier, height: 16 * sizeMultiplier}}/><div id={props.stickerType + "-txt"} draggable="false" className={cssClass} style={{ marginLeft: 6 * sizeMultiplier, fontSize: 22 * sizeMultiplier, textTransform: 'uppercase', paddingTop: 0.9}}>{title}</div>
            </div>
          </div>
        );
      }
    }
    else {
      return null;
    }

    function getStickerPosition(story, storyDimensions, stickerType){
      
      const position = {
        x: 0,
        y: 0
      }
    
      if (story == null){
        return position;
      }          
  
      let selectorHeightModifier = stickerDimensions.height / 2;
      let selectorWidthModifier = stickerDimensions.width / 2;
    
      let xPositionPct = 0;
      let yPositionPct = 0;
      if (stickerType === menuType){
        xPositionPct = story.mediasCenterXpct;
        yPositionPct = story.mediasCenterYpct;        
      }
      else if (stickerType === insideType){
        xPositionPct = story.virtualTourCenterXpct;
        yPositionPct = story.virtualTourCenterYpct;
      }      
   
      position.x = storyDimensions.videoWidthOffset / 2 + storyDimensions.offsetWidth + Math.round((storyDimensions.originalVideoWidth - storyDimensions.videoWidthOffset) * xPositionPct / 100) - selectorWidthModifier;  
      position.y = storyDimensions.videoHeightOffset / 2 + storyDimensions.offsetHeight + Math.round((storyDimensions.originalVideoHeight - storyDimensions.videoHeightOffset) * yPositionPct / 100) - selectorHeightModifier;
      
      if (stickersOutOfScreen || position.x < 0 || position.y < 0 || position.x > storyDimensions.containerWidth - stickerDimensions.width || position.y > storyDimensions.containerHeight - stickerDimensions.height){      
        if (playMode != null && playMode !== "preview"){
          setStickersOutOfScreen(true);
        }
        
        if (stickerType === menuType){
          position.x = storyDimensions.containerWidth / 2 - stickerDimensions.width / 2;
          position.y = storyDimensions.containerHeight * 7 / 10 - stickerDimensions.height;
        }
        else if (stickerType === insideType){
          position.x = storyDimensions.containerWidth / 2 - stickerDimensions.width / 2;
          position.y = storyDimensions.containerHeight * 3 / 10 - stickerDimensions.height;
        }
      }

      return position;
    }
}

export default Sticker;