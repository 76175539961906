import React from "react";
import styles from "./InputDesign.module.css";

function ColorInput({ label, value, onChange }) {
  return (
    <div className={styles.colorInputContainer}>
      <label className={styles.inputLabel}>{label}</label>
      <div className={styles.colorInputWrapper}>
        <input
          type="color"
          className={styles.colorPicker}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          aria-label={`Color picker for ${label}`}
        />
        <input
          type="text"
          className={styles.colorValue}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          aria-label={`Color value for ${label}`}
        />
      </div>
    </div>
  );
}

export default ColorInput;
