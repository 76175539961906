import { useState } from "react";

import './../ProductPage.css';
import { useTranslation } from "react-i18next";
import Player from  "./../player/Player";
import WebPageMock from "./../player/side_menu/components/WebPageMock";


function SalesDeck(props) {

    const { t } = useTranslation();

    document.title = t('ProductPageTitle');


    document.getElementsByTagName('body')[0].className = 'WhiteBackground';
    document.getElementById("theme-color").setAttribute("content", "#FFFFFF");
       

    const deskTop =  window.innerHeight >= 80 + 16 + 576 + 16
    const appBarHeight = deskTop ? 80 : 60;
    const logoSize = deskTop ? 42 : 26;
    const emojiSize = deskTop ? 26 : 16;
    const deviceBgHeight = deskTop ? 576 : 516;
    const storyTopMargin = deskTop ? 16 : 14;
    const storyHeight = deskTop ? 540 : 486;
    const logo_underline_margin_left = deskTop ? 26 : 20;
    const logo_underline_width = deskTop ? 180 : 100;

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', alignContent: 'center', justifyContent: 'center', }}>            
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignContent: "center", width: 1152}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><div style={{color: "#04d3bc", fontSize: logoSize, fontWeight: "bold"}}>UpReality </div> <div style={{fontSize: emojiSize,}}>&nbsp;🌊</div></div>
                <div style={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", alignItems: "center", visibility: "hidden"}}>
                    <img src="/images/ribbon_beta_right.png" height={appBarHeight} alt="beta ribbon" style={{marginRight: 40}}/>                                            
                </div>
            </div>
            {<div style={{width: 1152}}>
                <div style={{marginLeft: logo_underline_margin_left, width: logo_underline_width, height: 1, background: "radial-gradient(#b2ede7, #ffffff)"}} />                
            </div>}
            <div className="FirstSectionContainer" style={{height: window.innerHeight - appBarHeight, width: '100%', display: "flex", flexDirection: "column", justifyContent: 'center'}}>             
                <h1 style={{ marginTop: 40, width: '100%', textAlign: "center", fontSize: 36}}>La pub sociale pour restaurant aujourd'hui</h1>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 40}}>
                    <div style={{width: 500, marginTop: 100}}>
                        <font style={{fontSize: 24, fontWeight: "bold"}}>Une pub vers un site web</font>                        
                        <br/><br/>
                        <i>Le format le plus utilisé</i>
                        <br/><br/><br/><br/><br/>
                        <font style={{fontSize: 24, fontWeight: "bold"}}>Une pub vers une Instant Page</font><br/><br/>
                        <i>Version réduite du site web avec un affichage plein écran et un temps de chargement optimisé - peu utilisé</i>
                        <ul>
                            <li>Meta Instant Experience</li>
                            <li>TikTok Instant Page</li>
                        </ul>
                        <br/><br/><br/>                                  
                    </div>
                    <div>
                        <div style={{marginLeft: 40, }}>      
                            <img src={"/images/from_ig_ig_profile_AD.webp"} alt="from instagram profile" style={{height: deviceBgHeight}} />
                        </div>
                    </div>
                    <div style={{ marginLeft: 16, width: 274, height: deviceBgHeight + 40,  }}>
                        <div style={{marginLeft: 14, position: 'relative', top: storyTopMargin}}>                            
                            <WebPageMock id="web_demo" open={true} url={"/demoWeb/index.html"} width={244} height={storyHeight} imageOverWebsite={false} close={()=>{}} />                                                        
                        </div>
                        <img src="/images/transparent_framed.png" style={{position: "relative", top: 0, width:274, height:deviceBgHeight, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "274px " + deviceBgHeight + "px", pointerEvents: "none"}} />
                        <div style={{width: 274, textAlign: "center"}}>Site web</div>
                    </div>
                    <div style={{ marginLeft: 64, width: 274, height: deviceBgHeight + 40,  }}>
                        <div style={{marginLeft: 14, position: 'relative', top: storyTopMargin}}>                            
                            <WebPageMock id="instant_page_demo" open={true} url={"/demoInstantPage/InstantPage.html"} width={250} height={storyHeight} imageOverWebsite={false} close={()=>{}} />
                        </div>
                        <img src="/images/transparent_framed.png" style={{position: "relative", top: 0, width:280, height:deviceBgHeight, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "280px " + deviceBgHeight + "px", pointerEvents: "none"}} />
                        <div style={{width: 280, textAlign: "center"}}>Instant Page</div>
                    </div>                                                                                
                </div>
            </div>
            <div className="FirstSectionContainer" style={{height: window.innerHeight, width: '100%', display: "flex", flexDirection: "column", justifyContent: 'center'}}>             
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center',}}>
                    
                    <div style={{width: 500, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", alignContent: "flex-start", }}>                        
                        <div style={{}}>
                            <h1>{t("InstantAdHero1")}&nbsp;<div className="yellowParallelogram"><div className="textOverYellowParallelogram">{t("InstantAdHero2")}&nbsp;</div></div><br/>{t("InstantAdHero3")}</h1>
                            <font style={{fontSize: 20,}}>{t("InstantAdHero41")} {t("InstantAdHero42")}</font>
                            <br/><br/><br/>                                  
                        </div>
                        <h2 style={{marginTop: 40}}>{t("Benefit1")}</h2>
                        <div style={{}}>                            
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div style={{lineHeight: 1.6}}><font style={{fontSize: 18}}>{t("SubBenefit11")}</font><br/><i>{t("SubBenefit11det")}</i></div>
                            </div>
                            <br/>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div style={{lineHeight: 1.6}}><font style={{fontSize: 18}}>{t("SubBenefit12")}</font><br/><i>{t("SubBenefit12det")}</i></div>
                            </div>
                            <br/>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <img src="/images/ic_check.png" />
                                <div style={{lineHeight: 1.6}}><font style={{fontSize: 18}}>{t("SubBenefit13")}</font><br/><i>Des stats pour vérifier que votre Instant Ad est plus engageante et converti mieux</i></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{marginLeft: 40, }}>      
                            <img src={"/images/from_ig_ig_profile_AD.webp"} alt="from instagram profile" style={{height: deviceBgHeight}} />
                        </div>
                    </div>
                    {<div style={{ marginLeft: 10, width: 274, height: deviceBgHeight, }}>      
                        <div style={{marginLeft: 14, position: 'relative', top: storyTopMargin}}>                                                  
                            <Player fromMaker={false} fromDemo={"sales"} mini={true} storyId={"cafeuprealityad"} windowDimensions={{width: 244, height: storyHeight}} />
                        </div>
                        <img src="/images/transparent_framed.png" style={{position: "relative", top: 0, width: 274, height: deviceBgHeight, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "274px " + deviceBgHeight + "px", pointerEvents: "none"}} />
                    </div>}                                                                                    
                </div>
            </div>                       
            {
                <div style={{height: window.innerHeight, width: '100%', }}>
                    <h2 style={{ marginTop: 80, width: '100%', textAlign: "center", fontSize: 36}}>Comparer un site web mobile avec une Instant Ad UpReality</h2>
                    <div style={{height: window.innerHeight - 130, marginTop: 0, display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                        <div style={{marginTop: -20, display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            {
                                <div style={{display: 'flex', flexDirection: 'row', height: deviceBgHeight,}}>
                                    <div style={{marginTop: -40, height: deviceBgHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        {t('WebsiteQ1')}
                                        <div style={{marginTop: 40}}/>
                                        {t('WebsiteQ2')}
                                        <div style={{marginTop: 40}}/>
                                        {t('WebsiteQ3')}
                                    </div>
                                    <div style={{ marginLeft: 16, width: 274, height: deviceBgHeight,  }}>
                                        <div style={{marginLeft: 14, position: 'relative', top: storyTopMargin}}>                            
                                            <WebPageMock id="web_demo_2" open={true} url={"/demoWeb/index.html"} width={244} height={storyHeight} imageOverWebsite={false} close={()=>{}} />
                                        </div>
                                        <img src="/images/transparent_framed.png" style={{position: "relative", top: 0, width:274, height:deviceBgHeight, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "274px " + deviceBgHeight + "px", pointerEvents: "none"}} />
                                    </div>
                                </div>
                            }  
                            {
                                <div style={{marginLeft: 126, display: 'flex', flexDirection: 'row', height: deviceBgHeight,}}>
                                    <div style={{marginTop: -40, height: deviceBgHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        {t('StoryQ1')}
                                        <div style={{marginTop: 40}}/>
                                        {t('StoryQ2')}
                                        <div style={{marginTop: 40}}/>
                                        {t('StoryQ3')}
                                    </div>
                                    <div style={{ marginLeft: 16, width: 274, height: deviceBgHeight,  }}>      
                                        <div style={{marginLeft: 14, position: 'relative', top: storyTopMargin}}>                                                  
                                            <Player fromMaker={false} fromDemo={"bottom"} mini={true} storyId={"cafeupreality"} windowDimensions={{width:244, height: storyHeight}} />
                                        </div>
                                        <img src="/images/transparent_framed.png" style={{position: "relative", top: 0, width:274, height:deviceBgHeight, backgroundImage: "url('/images/transparent_framed.png')", backgroundSize: "274px " + deviceBgHeight + "px", pointerEvents: "none"}} />
                                    </div> 
                                </div>
                            }
                        </div>
                    </div>
                </div>
                }                    
                        
        </div>
    );
    
}

export default SalesDeck;