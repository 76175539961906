import React, { useState, useRef, useCallback } from "react";
import styles from "./InputDesign.module.css";

const PageEntry = ({
  pageEntry,
  pageEntryIndex,
  deletePageEntry,
  addEntry,
  deleteEntry,
  updatePageEntry,
  currentLanguage,
  pages,
  setPages,
  selectedPage,
  onDragStart,
  onDragOver,
  onDrop,
  onDragEnd,
  isDragging,
}) => {
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const [draggedOverItemIndex, setDraggedOverItemIndex] = useState(null);
  const [isUploading, setIsUploading] = useState({});
  const fileInputRefs = useRef({});

  const handleEntryTypeChange = (e) => {
    const updatedPageEntry = {
      ...pageEntry,
      entryType: e.target.value,
    };
    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  const handleTextChange = (value) => {
    const updatedText = {
      ...(pageEntry.text || {}),
      [currentLanguage]: value,
    };

    const updatedPageEntry = {
      ...pageEntry,
      text: updatedText,
    };

    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  const handleEntriesTitleChange = (value) => {
    const updatedEntriesTitle = {
      ...(pageEntry.entriesTitle || {}),
      [currentLanguage]: value,
    };

    const updatedPageEntry = {
      ...pageEntry,
      entriesTitle: updatedEntriesTitle,
    };

    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  const handleEntryChange = (entryIndex, field, value) => {
    const updatedEntries = [...pageEntry.entries];

    if (field === "entryImage") {
      updatedEntries[entryIndex][field] = value;
    } else {
    if (!updatedEntries[entryIndex][field]) {
      updatedEntries[entryIndex][field] = {};
    }
      updatedEntries[entryIndex][field][currentLanguage] = value;
    }

    const updatedPageEntry = {
      ...pageEntry,
      entries: updatedEntries,
    };

    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  const handlePriceChange = (entryIndex, language, priceIndex, value) => {
    const updatedEntries = [...pageEntry.entries];

    if (!updatedEntries[entryIndex].entryPrice) {
      updatedEntries[entryIndex].entryPrice = { fr: [""], en: [""] };
    }

    if (!updatedEntries[entryIndex].entryPrice[language]) {
      updatedEntries[entryIndex].entryPrice[language] = [""];
    }

    const prices = [...updatedEntries[entryIndex].entryPrice[language]];
    prices[priceIndex] = value;

    updatedEntries[entryIndex].entryPrice[language] = prices;

    const updatedPageEntry = {
      ...pageEntry,
      entries: updatedEntries,
    };

    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  const addPrice = (entryIndex, language) => {
    const updatedEntries = [...pageEntry.entries];

    if (!updatedEntries[entryIndex].entryPrice) {
      updatedEntries[entryIndex].entryPrice = { fr: [""], en: [""] };
    }

    if (!updatedEntries[entryIndex].entryPrice[language]) {
      updatedEntries[entryIndex].entryPrice[language] = [""];
    }

    updatedEntries[entryIndex].entryPrice[language].push("");

    const updatedPageEntry = {
      ...pageEntry,
      entries: updatedEntries,
    };

    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  const removePrice = (entryIndex, language, priceIndex) => {
    const updatedEntries = [...pageEntry.entries];
    const prices = [...updatedEntries[entryIndex].entryPrice[language]];

    if (prices.length > 1) {
      prices.splice(priceIndex, 1);
      updatedEntries[entryIndex].entryPrice[language] = prices;

      const updatedPageEntry = {
        ...pageEntry,
        entries: updatedEntries,
      };

      updatePageEntry(pageEntryIndex, updatedPageEntry);
    }
  };

  const handleImageUpload = async (entryIndex, file) => {
    if (!file) return;

    setIsUploading(prev => ({ ...prev, [entryIndex]: true }));
    try {
      // Créer un FormData pour l'upload
      const formData = new FormData();
      formData.append('image', file);

      // Envoyer l'image au serveur (à adapter selon votre endpoint)
      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Erreur lors du téléchargement');
      }

      const data = await response.json();
      handleEntryChange(entryIndex, "entryImage", data.url);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Erreur lors du téléchargement de l\'image');
    } finally {
      setIsUploading(prev => ({ ...prev, [entryIndex]: false }));
    }
  };

  const handleDragStart = (index) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    setDraggedOverItemIndex(index);
  };

  const handleDrop = (event, index) => {
    event.preventDefault();

    if (draggedItemIndex === null || draggedItemIndex === index) return;

    const updatedEntries = [...pageEntry.entries];
    const itemToMove = updatedEntries[draggedItemIndex];

    updatedEntries.splice(draggedItemIndex, 1);
    updatedEntries.splice(index, 0, itemToMove);

    const updatedPageEntry = {
      ...pageEntry,
      entries: updatedEntries,
    };

    updatePageEntry(pageEntryIndex, updatedPageEntry);

    setDraggedItemIndex(null);
    setDraggedOverItemIndex(null);
  };

  const handleDragEnd = () => {
    setDraggedItemIndex(null);
    setDraggedOverItemIndex(null);
  };

  const handleAddEntry = useCallback((pageEntryIndex, isSeparator = false) => {
    if (selectedPage !== null) {
      const newPages = [...pages];
      if (isSeparator) {
        newPages[selectedPage].pageEntries[pageEntryIndex].entries.push({
          entryId: Date.now(),
          separator: true,
        });
      } else {
        newPages[selectedPage].pageEntries[pageEntryIndex].entries.push({
          entryId: Date.now(),
          entryTitle: { fr: "", en: "" },
          entrySubtitle: { fr: "", en: "" },
          entryPrice: { fr: [""], en: [""] },
        });
      }
      setPages(newPages);
    }
  }, [selectedPage, pages]);

  const handleFormulaPriceChange = (value) => {
    const updatedPageEntry = {
      ...pageEntry,
      formulaPrice: value,
    };
    updatePageEntry(pageEntryIndex, updatedPageEntry);
  };

  return (
    <div 
      className={`${styles.pageEntry} ${isDragging ? styles.dragging : ''}`}
      draggable
      onDragStart={onDragStart}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e)}
      onDragEnd={onDragEnd}
    >
      <div className={styles.flexJustifyBetweenItemsCenter}>
        <div className={styles.flexGap} style={{ alignItems: "center" }}>
          <select
            value={pageEntry.entryType || ""}
            onChange={handleEntryTypeChange}
            className={styles.inputField}
            style={{ maxWidth: "300px" }}
          >
            <option value="">Select Entry Type</option>
            <option value="startingNote">Starting Note</option>
            <option value="endingNote">Ending Note</option>
            <option value="note">Note</option>
            <option value="dishList">Dish List</option>
            <option value="formula">Formula</option>
          </select>
          <span 
            style={{ 
              color: "#666", 
              cursor: "grab", 
              userSelect: "none",
              opacity: isDragging ? 0.5 : 1,
              transform: isDragging ? "scale(1.02)" : "none",
              transition: "opacity 0.2s, transform 0.2s"
            }}
          >
            ☰
          </span>
        </div>
        <button
          onClick={() => deletePageEntry(pageEntryIndex)}
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#ef4444",
            cursor: "pointer",
          }}
        >
          Delete
        </button>
      </div>

      {(pageEntry.entryType === "startingNote" ||
        pageEntry.entryType === "endingNote" ||
        pageEntry.entryType === "note") && (
        <div className={styles.flexFlexColGap} style={{ marginTop: "16px" }}>
          <div className={styles.flexGap}>
            <textarea
              value={pageEntry.text?.[currentLanguage] || ""}
              onChange={(e) => handleTextChange(e.target.value)}
              placeholder={currentLanguage === "fr" ? "Texte en français" : "English Text"}
              className={styles.inputField}
              style={{ minHeight: "200px" }}
            />
          </div>
        </div>
      )}

      {(pageEntry.entryType === "dishList" ||
        pageEntry.entryType === "formula") && (
        <>
          <div
            className={styles.flexFlexColGap}
            style={{ marginBottom: "16px", marginTop: "16px" }}
          >
            <div className={styles.flexGap}>
              <input
                type="text"
                value={pageEntry.entriesTitle?.[currentLanguage] || ""}
                onChange={(e) => handleEntriesTitleChange(e.target.value)}
                placeholder={currentLanguage === "fr" ? "Titre en français" : "English Title"}
                className={styles.inputField}
                style={{ maxWidth: "300px" }}
              />
              {pageEntry.entryType === "formula" && (
              <input
                type="text"
                  value={pageEntry.formulaPrice || ""}
                  onChange={(e) => handleFormulaPriceChange(e.target.value)}
                  placeholder="Prix du menu (ex: 19.90 €)"
                className={styles.inputField}
                  style={{ maxWidth: "150px" }}
              />
              )}
            </div>
          </div>

          <div className={styles.flexFlexColGap}>
            {pageEntry.entries?.map((entry, entryIndex) => (
              <div
                key={entryIndex}
                className={styles.flexFlexColGap}
                  style={{
                  marginBottom: "16px",
                  padding: entry.separator ? "8px 16px" : "16px",
                  border: "1px solid #b2ede7",
                    borderRadius: "8px",
                  cursor: "move",
                  backgroundColor: draggedOverItemIndex === entryIndex ? "#f0f9f9" : "white"
                }}
                draggable
                onDragStart={() => handleDragStart(entryIndex)}
                onDragOver={(e) => handleDragOver(e, entryIndex)}
                onDrop={(e) => handleDrop(e, entryIndex)}
                onDragEnd={handleDragEnd}
              >
                {entry.separator ? (
                  <div className={styles.flexJustifyBetweenItemsCenter}>
                    <div style={{ 
                      height: "1px", 
                      backgroundColor: "#b2ede7", 
                      flex: 1,
                      margin: "8px 0"
                    }} />
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "16px" }}>
                      <span style={{ color: "#666", cursor: "move" }}>☰</span>
                    <button
                      onClick={() => deleteEntry(pageEntryIndex, entryIndex)}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "#ef4444",
                        cursor: "pointer",
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  </div>
                ) : (
                  <>
                    <div className={styles.flexJustifyBetweenItemsCenter}>
                  <div className={styles.flexFlexColGap}>
                      <input
                        type="text"
                          value={entry.entryTitle?.[currentLanguage] || ""}
                        onChange={(e) =>
                            handleEntryChange(entryIndex, "entryTitle", e.target.value)
                          }
                          placeholder={currentLanguage === "fr" ? "Titre en français" : "English Title"}
                        className={styles.inputField}
                          style={{ width: 300, maxWidth: "300px" }}
                      />
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(entryIndex, e.target.files[0])}
                          style={{ display: 'none' }}
                          ref={el => fileInputRefs.current[entryIndex] = el}
                        />
                        <button
                          onClick={() => fileInputRefs.current[entryIndex]?.click()}
                          className={styles.primaryButton}
                          style={{ 
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            width: "fit-content"
                          }}
                        >
                          {isUploading[entryIndex] ? (
                            <span>Téléchargement...</span>
                          ) : (
                            <>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                                <circle cx="8.5" cy="8.5" r="1.5" />
                                <polyline points="21 15 16 10 5 21" />
                              </svg>
                              {entry.entryImage && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEntryChange(entryIndex, "entryImage", "");
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#ef4444",
                                    cursor: "pointer",
                                    padding: "0 4px",
                                  }}
                                >
                                  ×
                                </button>
                              )}
                            </>
                          )}
                        </button>
                        {entry.entryImage && (
                          <img
                            src={entry.entryImage}
                            alt=""
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                              borderRadius: "4px",
                            }}
                          />
                        )}
                        <span style={{ color: "#666", cursor: "move" }}>☰</span>
                        <button
                          onClick={() => deleteEntry(pageEntryIndex, entryIndex)}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#ef4444",
                            cursor: "pointer",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div className={styles.flexFlexColGap}>
                      <input
                        type="text"
                        value={entry.entrySubtitle?.[currentLanguage] || ""}
                        onChange={(e) =>
                          handleEntryChange(
                            entryIndex,
                            "entrySubtitle",
                            e.target.value
                          )
                        }
                        placeholder={currentLanguage === "fr" ? "Sous-titre en français" : "English Subtitle"}
                        className={styles.inputField}
                        style={{ maxWidth: "300px" }}
                      />
                    <div className={styles.flexGap}>
                        {(entry.entryPrice?.[currentLanguage] || [""]).map((price, priceIndex) => (
                          <div key={priceIndex} className={styles.flexGap}>
                            <input
                              type="text"
                              value={price}
                              onChange={(e) =>
                                handlePriceChange(
                                  entryIndex,
                                  currentLanguage,
                                  priceIndex,
                                  e.target.value
                                )
                              }
                              placeholder="0.00 €"
                              className={styles.inputField}
                              style={{ maxWidth: "100px" }}
                            />
                            {priceIndex === entry.entryPrice?.[currentLanguage]?.length - 1 ? (
                                <button
                                onClick={() => addPrice(entryIndex, currentLanguage)}
                                  style={{
                                  backgroundColor: "transparent",
                                    border: "none",
                                  color: "#2fc4b5",
                                    cursor: "pointer",
                                  fontSize: "20px",
                                  }}
                                >
                                  +
                                </button>
                              ) : (
                                <button
                                onClick={() => removePrice(entryIndex, currentLanguage, priceIndex)}
                                  style={{
                                  backgroundColor: "transparent",
                                    border: "none",
                                  color: "#ef4444",
                                    cursor: "pointer",
                                  fontSize: "20px",
                                  }}
                                >
                                ×
                                </button>
                              )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
            <div className={styles.flexGap}>
                                <button
                onClick={() => handleAddEntry(pageEntryIndex)}
                className={styles.primaryButton}
                style={{ width: "fit-content" }}
              >
                Ajouter un item
                                </button>
              {pageEntry.entryType === "formula" && (
                                <button
                  onClick={() => handleAddEntry(pageEntryIndex, true)}
                  className={styles.primaryButton}
                  style={{ width: "fit-content" }}
                >
                  Ajouter un séparateur
                                </button>
                              )}
                            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PageEntry;
