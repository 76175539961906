import React from "react";
import styles from "./TestimonialsSection.module.css";
import ProfileSection from "./ProfileSection";

const TestimonialCard = ({ name, role, text, avatar }) => {
  return (
    <article className={styles.testimonialCard}>
      <ProfileSection name={name} role={role} avatar={avatar} />
      <p className={styles.testimonialText}>{text}</p>
    </article>
  );
};

export default TestimonialCard;