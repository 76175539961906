import React from "react";
import styles from "./InputDesign.module.css";

function FontInput({
  label,
  nameValue,
  locationValue,
  onNameChange,
  onLocationChange,
}) {
  return (
    <div className={styles.fontInputContainer}>
      <label className={styles.inputLabel}>{label}</label>
      <input
        type="text"
        placeholder="Font Name"
        className={styles.fontInput}
        value={nameValue}
        onChange={(e) => onNameChange(e.target.value)}
        aria-label={`${label} name`}
      />
      <input
        type="text"
        placeholder="Font URL"
        className={styles.fontUrl}
        value={locationValue}
        onChange={(e) => onLocationChange(e.target.value)}
        aria-label={`${label} URL`}
      />
    </div>
  );
}

export default FontInput;
